import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import { cookie } from '../services';

const Menu = ({ routePaths, componentName, sideBarCollapsed })  => {
  return (
          <>
  <aside className={`main-sidebar sidebar-dark-primary elevation-4 ${sideBarCollapsed ? 'collapsed' : ''}`}>
    {/* Brand Logo */}
    <a href="/" className="brand-link text-center bg-gradient-info">
      {/* <img src="dist/img/AdminLTELogo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{opacity: '.8'}} /> */}
      <span className="brand-text ">GAMAGE JEWELLERY </span>
    </a>
    {/* Sidebar */}
    <div className="sidebar">
      {/* Sidebar user panel (optional) */}
      <div className="user-panel mt-3 pb-3 mb-3 d-flex">
        <div className="image">
          <img src="dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" />
        </div>
        <div className="info">
          <a href="#" className="d-block">{ cookie.get('user_name')} </a>
        </div>
      </div>
      {/* Sidebar Menu */}
      <nav className="mt-2">
        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          {/* Add icons to the links using the .nav-icon class
           with font-awesome or any other icon font library */}

{routePaths.map((route, index) => (
  route.pathURL !== '/pos' && 
  (cookie.get('user_name') === 'Admin' || (route.pathURL !== '/employees' && route.pathURL !== '/users')) ? (
    <li className="nav-item" key={index}>
      <NavLink
        exact
        to={route.pathURL}
        className="nav-link"
        activeClassName="active"
        onClick={() => componentName(route.name)}
      >
        {route.name}
      </NavLink>
    </li>
  ) : null
))}


         
        
        </ul>
      </nav>
      {/* /.sidebar-menu */}
    </div>
    {/* /.sidebar */}
  </aside>
</>

        );
    };
export default Menu;
