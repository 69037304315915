import React, { useState, useEffect } from "react";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { Loader, SystemButton, FormModal } from "./";
import { api, auth, cookie, msg } from "../services";

import SalesOrderPrint from "../printouts/SalesOrderPrintA4Half";
import { loginLogo } from "../assets";

import { Dropdown } from "primereact/dropdown";

const signOut = async () => {
  auth.signOutUser();
};

const PosHeader = () => {
  // Module name
  const moduleName = "POS";

  const [isLoading, setIsLoading] = useState({
    init: false,
  });
  const [selectedBook, setSelectedBook] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  
  const [discountType, setDiscountType] = useState("Flat");
  const [discountValue, setDiscountValue] = useState(0);
  const [items, setItems] = useState([]);

  const [newData, setNewData] = useState({
    user_id: cookie.get("user_id"),
    branch_id: cookie.get("user_branch"),
    grand_total: 0.0,
    sub_total: 0.0,
    discount: 0.0,
    ddate: moment().format(`YYYY-MM-DD`),
    customer_id: "",
    employee_id: "",
    booking_id: "",
    additional:"",
    nicno: "",
    cusname: "",
    address: "",
    mobile: "",
    details: [],
    paying_amount: '',
    paid_amount: '',
    paid_by_id_select: 'Cash',
    tips: false,
    sale_note: '',
    change:0.00
  });

  const [salesDetails, setSalesDetails] = useState([]);

  const [customerData, setCustomerData] = useState({
    name: "",
    nic: "",
    old_nic: "",
    address: "",
    telephone: "",
    mobile_1: "",
    mobile_2: "",
    email: "",
    notes: "",
    branch_id: cookie.get("user_branch"),
    user_id: cookie.get("user_id"),
    date: moment().format(`YYYY-MM-DD`),
   
  });



  const [customers, setCustomers] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [viewCustomerForm, setViewCustomerForm] = useState(false);
  const [viewPaymentForm, setViewPaymentForm] = useState(false);

  const [showCustomerModalState, setShowCustomerModalState] = useState(false);
  const [showModalState, setShowModalState] = useState(false);
  const [showDiscountModalState, setShowDiscountModalState] = useState(false);

  /* --- Customer List Selection Required ---- */
  const [viewCustomerListSelection, setViewCustomerListSelection] = useState(
    false
  );
  const showCustomerListSelection = () => {
    setViewCustomerListSelection(!viewCustomerListSelection);
  };

  const selectCustomer = (dataObj) => {
    setCustomerData({ ...dataObj, branch_code: dataObj.bc });
  };
  /* ---  End of Customer List Selection Required ---- */

  useEffect(() => {
    fetchData();
    fetchBranches();
  }, []);

  const toggleDiscountModal = () => {
    setShowDiscountModalState(!showDiscountModalState);
  };

  const showPaymentForm = () => {
    setNewData(prevState => ({
      ...prevState,
      paid_amount: prevState.grand_total,
      paying_amount: prevState.grand_total,
      change: '0.00'
    }));
    setViewPaymentForm(!viewPaymentForm);
  };


  const [branchList, setBranchList] = useState([]);

  const handleCustomerChange = (event) => {
    setSelectedCustomer(event.target.value);
    console.log(event.target.value.id);
    setNewData({
      ...newData,
      customer_id: event.target.value.id,
    });
  };
  const handleEmployeeChange = (event) => {
    setSelectedEmployee(event.target.value);
    console.log(event.target.value.id);
    setNewData({
      ...newData,
      employee_id: event.target.value.id,
    });
  };

  // List of districts
  const districtsList = [
    { code: "JAF", des: "Jaffna" },
    { code: "KIL", des: "Kilinochchi" },
    { code: "MAN", des: "Mannar" },
    { code: "MUL", des: "Mullaitivu" },
    { code: "VAV", des: "Vavuniya" },
    { code: "PUT", des: "Puttalam" },
    { code: "KUR", des: "Kurunegala" },
    { code: "GAM", des: "Gampaha" },
    { code: "COL", des: "Colombo" },
    { code: "KAL", des: "Kalutara" },
    { code: "MAT", des: "Matale" },
    { code: "KAN", des: "Kandy" },
    { code: "NUW", des: "Nuwara Eliya" },
    { code: "KEG", des: "Kegalle" },
    { code: "RAT", des: "Ratnapura" },
    { code: "TRI", des: "Trincomalee" },
    { code: "BAT", des: "Batticaloa" },
    { code: "AMP", des: "Ampara" },
    { code: "BAD", des: "Badulla" },
    { code: "MON", des: "Monaragala" },
    { code: "HAM", des: "Hambantota" },
    { code: "MAT", des: "Matara" },
    { code: "GAL", des: "Galle" },
  ];

  /* ---  End of List Selection Required ---- */

  /* --- Component functions --- */
  const fetchData = async () => {
    try {
      // setIsLoading({
      //   ...isLoading,
      //   init: true,
      // });

      const response = await api.get(`sales-orders`);

      setCustomers(response.data.customers);
      setEmployees(response.data.employees);
      setItems(response.data.items);

      setNewData({
        ...newData,
        ddate: response.data.ddate,
        newDate: response.data.ddate,
        id: response.data.new_id,
        newId: response.data.new_id,

        gold_rate: "",
        store_id: "",
        customer_id: "",
        nicno: "",
        cusname: "",
        address: "",
        mobile: "",
        memo: "",
        employee_id: "",
        tot_qty: 0,
        tot_weight: 0,
        tot_st_weight: 0,
        tot_price: 0,
        tot_amount: 0,
        discount: 0,
        net_amount: 0,

        details: [],
      });

      setCustomerData({
        customer_id: "",
        cusname: "",
        nicno: "",
        address: "",
        telNo: "",
        mobile: "",
        notes: "",
        is_blacklisted: 0,
        branch_code: response.data.branch.bc,
        customer_no: response.data.customer_max_no,
      });

      setIsLoading({
        ...isLoading,
        init: false,
      });
    } catch (error) {
      return msg.error("Unable to fetch data!");
    }
  };

  const toggleCustomerModal = () => {
    setShowCustomerModalState(!showCustomerModalState);
  };
  const togglePaymentFormModal = () => {
    setViewPaymentForm(!viewPaymentForm);
    setNewData({
      ...newData,
      paying_amount: '',
    paid_amount: '',
    paid_by_id_select: 'Cash',
    tips: false,
    sale_note: ''

    })

  };

  const [change, setChange] = useState('0.00');
  const [initial, setInitial] = useState(true);


  const handleValueChange = (e) => {
    const targetInput = e.target;
    const inputName = targetInput.name;
    const inputValue =
      targetInput.type === "file" ? targetInput.files[0] : targetInput.value;

    setCustomerData({
      ...customerData,
      [inputName]: inputValue,
    });
  };

  const fetchBranches = async () => {
    try {
      const response = await api.get("all-branches");

      if (response.error) {
        Object.values(response.error).forEach((err) => {
          msg.error(err[0]);
        });
        return;
      } else {
        setBranchList(response.data);
      }
    } catch (error) {
      return console.log(error);
    }
  };

  const handleAddToSalesDetails = (newItem) => {
    // Initialize qty to 1 if it doesn't exist in the newItem
    const itemWithQty = { ...newItem, qty: newItem.qty === null || newItem.qty === undefined ? 1 : newItem.qty };
  
    // Check if the item already exists in the sales details
    const itemIndex = salesDetails.findIndex(item => item.item_code === itemWithQty.item_code);
    let updatedSalesDetails = [...salesDetails];
  
    if (itemIndex > -1) {
      // Item exists, update the quantity and price
      const existingItem = updatedSalesDetails[itemIndex];
      const updatedItem = { 
        ...existingItem,
        qty: existingItem.qty + 1,
        rent_price: existingItem.rent_price + itemWithQty.rent_price,
      };
      updatedSalesDetails[itemIndex] = updatedItem;
    } else {
      // Item does not exist, add new item
      updatedSalesDetails = [...updatedSalesDetails, itemWithQty];
    }
  
    // Calculate new subtotal and grand total
    const subTotal = updatedSalesDetails.reduce((total, item) => total + item.rent_price, 0);
    const grandTotal = calculateGrandTotal(subTotal, discountType, discountValue);
  
    setSalesDetails(updatedSalesDetails);
    setNewData((prevData) => ({
      ...prevData,
      sub_total: subTotal,
      grand_total: grandTotal,
    }));
  };
  
  
  
  
  const getCustomer = async (e) => {
    if (e.key === "Enter") {
      try {
        const response = await api
          .post(`get_customer_by_nic`)
          .values({ nic: newData.mobile });

        if (response.data.customer === null) {
          msg.warning(
            "There is no customer exist for entered Mobile No. Please check the Mobile No or create new by clicking Add button."
          );
          setNewData({
            ...newData,
            customer_id: "",
            nicno: "",
            cusname: "",
            address: "",
            mobile: "",
          });
        } else {
          setNewData({
            ...newData,
            customer_id: response.data.customer.customer_id,
            cusname: response.data.customer.cusname,
            address: response.data.customer.address,
            nicno: response.data.customer.nicno,
          });
        }
      } catch (error) {
        return msg.error("Unable to get customer details.");
      }
    } else if (e.key === "F2") {
      showCustomerListSelection();
    }
  };

  const supplierSelect = (selectedObj) => {
    setNewData({
      ...newData,
      nicno: selectedObj.code,
      cusname: selectedObj.description,
    });
  };

  const handleValueChanges = (e) => {
    const targetInput = e.target;
    const inputName = targetInput.name;
    const inputValue = targetInput.value;

    setNewData({
      ...newData,
      [inputName]: inputValue,
    });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //  // await save();
  // };

  
  const handleSubmitSale = async (e) => {
    e.preventDefault();
    //printReceipt(22, false);
   
      await save();
  
  };

  const save = async () => {
    //if(newData.paying_amount < 0.00){
    //  msg.success("Saved successfully!");
     //    return; 
   // }
      try {
        const response = await api.post("sales-orders").values({
          sales_order: newData,
          salesDetails: salesDetails,
        });

        if (response.status == 200 && response.data.status == 200) {
          msg.success("Saved successfully!");
          
          resetAll();
          setViewPaymentForm(!viewPaymentForm);
          printReceipt(response.data.id, false);
        } else if (response.status == 200 && response.data.status == 500) {
          msg.error(response.data.message);
        } else if (response.status == 200 && response.data.status == 400) {
          Object.values(response.data.message).forEach((err) => {
            msg.error(err[0]);
          });
        } else if (response.data.status == 400) {
          Object.values(response.message).forEach((err) => {
            msg.error(err[0]);
          });
        } else if (response.data.status == 520) {
          msg.warning(response.data.message);
        }
      
      } catch (err) {
        msg.error(err);
        return;
      }
   
    
  };

  const checkBeforeAddItem = () => {
    // if (itemRow.tag_no === "") {
    //   msg.warning("Enter Tag No.");
    //   return false;
    // }
    // if (itemRow.item_name === "") {
    //   msg.warning("Enter Tag No and load Item details.");
    //   return false;
    // }
    // if (itemRow.weight === "" || itemRow.weight === 0) {
    //   msg.warning("Enter Tag No and load Item details.");
    //   return false;
    // }
    // if (itemRow.st_weight === "" || itemRow.st_weight === 0) {
    //   msg.warning("Enter Tag No and load Item details.");
    //   return false;
    // }
    // if (itemRow.price === "" || itemRow.price === 0) {
    //   msg.warning("Enter Tag No and load Item details.");
    //   return false;
    // }
    // if (itemRow.agree_rate === "" || itemRow.agree_rate === 0) {
    //   msg.warning("Enter valid Agree Rate.");
    //   return false;
    // }
    // return true;
  };

  const removeItem = (index) => {
    // setSalesDetails((prevSalesDetails) => {
    //   const newSalesDetails = [...prevSalesDetails];
    //   const removedItem = newSalesDetails.splice(index, 1)[0];
    //   setTotalWeight(
    //     (prevTotalWeight) => prevTotalWeight - (removedItem.weight || 0)
    //   );
    //   setNewData({
    //     ...newData,
    //     sub_total:newData.sub_total-removedItem.rent_price
    //   })
    //   return newSalesDetails;
    // });
    setSalesDetails((prevSalesDetails) => {
      const newSalesDetails = [...prevSalesDetails];
      const removedItem = newSalesDetails.splice(index, 1)[0];
      const subTotal = newData.sub_total - removedItem.rent_price;
      const grandTotal = calculateGrandTotal(
        subTotal,
        discountType,
        discountValue
      );

      setNewData((prevData) => ({
        ...prevData,
        sub_total: subTotal,
        grand_total: grandTotal,
      }));

      return newSalesDetails;
    });
  };
  const calculateGrandTotal = (subTotal, discountType, discountValue) => {
    let discount = 0;
    if (discountType === "Flat") {
      discount = discountValue;
    } else if (discountType === "Percentage") {
      discount = (discountValue / 100) * subTotal;
    }

    setNewData({
      ...newData,
      discount: discount,
    });
    return subTotal - discount;
  };

  const handleSaveDiscount = () => {
    const grandTotal = calculateGrandTotal(
      newData.sub_total,
      discountType,
      discountValue
    );
    setNewData((prevData) => ({
      ...prevData,
      grand_total: grandTotal,
    }));
    setShowModalState(false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredItems = items.filter((item) =>
    //item.item_code.toLowerCase().includes(searchTerm.toLowerCase())
    item.item_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const resetAll = () => {
    // setIsLoading({
    //   init: false,
    // });

    setNewData({
      user_id: cookie.get("user_id"),
      branch_id: cookie.get("user_branch"),
      grand_total: 0.0,
      sub_total: 0.0,
      discount: 0.0,
      ddate: moment().format(`YYYY-MM-DD`),
      customer_id: "",
      employee_id: "",
      booking_id: "",
      additional:"",
      nicno: "",
      cusname: "",
      address: "",
      mobile: "",
      details: [],
      paying_amount: '',
      paid_amount: '',
      paid_by_id_select: 'Cash',
      tips: false,
      sale_note: '',
      change:0.00
    });

    setSalesDetails([]);
    setSelectedCustomer([]);
setSelectedEmployee([]);
  };

  const handleSubmitCustomer = async (e) => {
    e.preventDefault();
    await submitData();
  };

  const submitData = async () => {
    try {
      const response = await api.post("customers").values(customerData);
      if (response.status == 200 && response.data.status == 200) {
        msg.success(response.data.message);
        resetCustomerForm();

        setShowCustomerModalState(false);
      } else if (response.status == 200 && response.data.status == 500) {
        msg.error(response.data.message);
      } else if (response.status == 200 && response.data.status == 400) {
        Object.values(response.data.message).forEach((err) => {
          msg.error(err[0]);
        });
      } else if (response.data.status == 400) {
        Object.values(response.message).forEach((err) => {
          msg.error(err[0]);
        });
      } else if (response.data.status == 520) {
        msg.warning(response.data.message);
      }
    } catch (error) {
      msg.error(error);
    }
  };

  const handlePaymentValueChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setNewData(prevState => {
      const updatedState = {
        ...prevState,
        [name]: newValue
      };

      if (name === 'paying_amount' || name === 'paid_amount') {
        updateChange(updatedState.paying_amount, updatedState.paid_amount);
      }

      return updatedState;
    });
  };

  const showCustomerForm = () => {
    setViewCustomerForm(!viewCustomerForm);
  };

  const changeOrderType = (type) => {
    setNewData({
      ...newData,
      order_type: type,
    });
  };


  const handleQuickCashClick = (amount) => { 
    
    let newPayingAmount = parseFloat(newData.paying_amount);
    if(amount==0){
      newPayingAmount=0.00;
        }
    if (initial) {
      newPayingAmount = amount;
      setInitial(false);
    } else {
      newPayingAmount += amount;
    }

    newPayingAmount = newPayingAmount.toFixed(2);

    setNewData(prevState => {
      const updatedState = {
        ...prevState,
        paying_amount: newPayingAmount
      };

      updateChange(updatedState.paying_amount, updatedState.paid_amount);
      return updatedState;
    });
  };

  const updateChange = (paying_amount, paid_amount) => {
    const changeValue = (parseFloat(paying_amount) - parseFloat(paid_amount || 0)).toFixed(2);
    setNewData(prevState => ({
      ...prevState,
      change: changeValue
    }));
  };

  useEffect(() => {
    updateChange(newData.paying_amount, newData.paid_amount);
  }, [newData.paid_amount, newData.paying_amount]);

  const resetCustomerForm = () => {
    setNewData({
      name: "",
      nic: "",
      old_nic: "",
      address_1: "",
      address_2: "",
      telephone: "",
      email: "",
      notes: "",
      is_blacklisted: 0,
      branch_id: "",
      user_id: cookie.get("user_id"),
    });
  };

  const printReceipt = (saleid) => {
    SalesOrderPrint.load(saleid, cookie.get("user_name"),);
  };

  const toggleMoreModal = () => {
    setShowModalState(!showModalState);
  };

  /* --- End of component functions --- */

  /* --- Component renders --- */

  return (
    <>
      <nav className="main-header navbar navbar-expand-md  navbar-navy p-0">
        <div class="container" style={{ maxWidth: "unset" }}>
          {/* <a href="../../index3.html" class="navbar-brand"> */}
          <img
            src="../../dist/img/AdminLTELogo.png"
            alt="AdminLTE Logo"
            class="brand-image img-circle elevation-3"
            style={{ opacity: ".8" }}
          />
          <span class="brand-text text-white">GAMAGE JEWELLERY</span>
          {/* </a> */}

          <ul class="navbar-nav ml-auto">
            {/* <li class="nav-item">
              <a
                class="nav-link"
                data-widget="fullscreen"
                href="#"
                role="button"
              >
                <i class="fas fa-expand-arrows-alt"></i>
              </a>
            </li> */}
            <li class="nav-item">
              <a class="nav-link" onClick={() => signOut()} role="button">
                <i class="fas fa-sign-out-alt"></i>
              </a>
            </li>
          </ul>
        </div>
      </nav>

      <div class="content-wrapper">
        <div class="content">
          <section class="content">
            <div class="container-fluid">
              <div class="row mt-2">
                <div className="col-sm-8">
                  <div class="card card-primary">
                    <div class="card-body pt-1">
                      <div class="row ">
                        <div class="col-5">
                          <Dropdown
                            value={selectedCustomer}
                            onChange={handleCustomerChange}
                            options={customers}
                            optionLabel="full_name"
                            placeholder="Select a Customer"
                            filter
                            className="w-full md:w-14rem "
                            style={{ width: "400px" }}
                          />
  </div>                   <div class="col-1">
                          <SystemButton
                            classes={"btn btn-sm btn-success"}
                            type="add-new"
                            method={toggleCustomerModal}
                            showText
                            btnText="Add"
                          />
                        </div>
                        
                        <div class="col-6">
                        <Dropdown
                            value={selectedEmployee}
                            onChange={handleEmployeeChange}
                            options={employees}
                            optionLabel="full_name"
                            placeholder="Select a Employee"
                            filter
                            className="w-full md:w-14rem "
                            style={{ width: "450px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />

                  <div class="card">
                    <div class="card-header">
                      <input
                        type="text"
                        className="form-control form-control-sm rounded-0 bg-white"
                        id="search"
                        name="search"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Search Item Name"
                      />
                    </div>
                    <div
                      class="card-body"
                      style={{ maxHeight: "500px", overflowY: "auto" }}
                    >
                      <div>
                        <div class="filter-container p-0 row">
                          {filteredItems.map((item) => (
                            <div
                              key={item.item_name}
                              className="filtr-item col-sm-2"
                              data-category="1"
                              data-sort="white sample"
                              onClick={() => handleAddToSalesDetails(item)}
                            >
                              <img
                                src={loginLogo}
                                className="img-fluid mb-2"
                                alt="white sample"
                              />{" "}
                              <br />
                              <p className="text-ceter">
                                {item.item_name} - {item.rent_price}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="card">
                    <div class="card-body pt-1">
                      <div class="row">
                        <div class="col-3">
                          <label htmlFor="ddate">Date</label>
                        </div>
                        <div class="col-9">
                          <input
                            type="date"
                            className="form-control form-control-sm rounded-0"
                            id="ddate"
                            name="ddate"
                            value={newData.ddate}
                            onChange={handleValueChanges}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                  <div className="card mb-0">
                    <div class="card-body pt-1" style={{ height: "320px" }}>
                      <div
                        className="table-responsive header-fixed-scrollable"
                        style={{ maxHeight: "300px" }}
                      >
                        <table className="table table-bordered table-sm table-hover">
                          <thead className="thead-dark text-center">
                            <tr>
                              <td width="5%">#</td>
                              <td width="12%">Item.</td>
                              <td width="40%">Qty</td>
                              <td width="30%">Price (LKR)</td>
                              <td width="5%"></td>
                            </tr>
                          </thead>
                          <tbody>
                            {salesDetails.map((item, index) => (
                              <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>
                                  <input
                                    type="text"
                                    name="item_name"
                                    id="item_name"
                                    data-id={index}
                                    className="form-control-plaintext form-control-sm"
                                    value={item.item_name}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name="item_serial"
                                    id="item_serial"
                                    data-id={index}
                                    className="form-control form-control-sm"
                                    value={item.qty}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="rent_price"
                                    id="rent_price"
                                    step="0.01"
                                    data-id={index}
                                    className="form-control form-control-sm text-right"
                                    value={item.rent_price.toFixed(2)}
                                    readOnly
                                  />
                                </td>
                                <td className="text-center">
                                  <SystemButton
                                    type={"remove-row"}
                                    method={() => removeItem(index)}
                                    showText={false}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="card mb-0">
                    <div class="card-body pt-1">
                      <div className="row">
                        <div class="col-4 h4">
                          <span class="totals-title">Sub Total </span>
                        </div>

                        <div class="col-8 h3 text-right">
                          <span id="discount">
                            {newData.sub_total.toFixed(2)}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div class="col-4 h4">
                          <span class="totals-title">Discount </span>
                        </div>
                        <div class="col-4">
                          <SystemButton
                            classes={"btn btn-sm btn-danger"}
                            type="add-new"
                            method={toggleDiscountModal}
                            showText
                            btnText="Add Discount"
                          />
                        </div>
                        <div class="col-4 h3 text-right">
                          <span id="discount">
                            {newData.discount.toFixed(2)}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div class="col-4 h4">
                          <span class="totals-title">Grand Total </span>
                        </div>

                        <div class="col-8 h3 text-right">
                          <span id="grand_total">
                            {newData.grand_total.toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <SystemButton
                            type="reset"
                            showText
                            method={resetAll}
                          />
                        </div>
                        <div className="col-md-6 text-right">
                          <SystemButton
                            classes={"btn btn-sm btn-info btn-block  shadow-sm"}
                            type="payment"
                            showText
                            method={showPaymentForm}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <FormModal
        moduleName={"Discount"}
        modalState={showDiscountModalState}
        toggleFormModal={toggleDiscountModal}
        width="50%"
      >
        {/* <form onSubmit={handleSubmit} className="compactForm">
        </form> */}
        <div className="model-content">
          <div className="modal-body">
            <div class="row">
              <div class="col-md-6 form-group">
                <label>Discount Type</label>
                <select
                  id="order-discount-type"
                  name="order_discount_type_select"
                  class="form-control"
                  onChange={(e) => setDiscountType(e.target.value)}
                >
                  <option value="Flat">Flat</option>
                  <option value="Percentage">Percentage</option>
                </select>
                <input type="hidden" name="order_discount_type" />
              </div>
              <div class="col-md-6 form-group">
                <label>Value</label>
                <input
                  type="text"
                  class="form-control text-right"
                  value={discountValue}
                  onChange={(e) =>
                    setDiscountValue(parseFloat(e.target.value) || 0)
                  }
                />
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <SystemButton
              type={"close"}
              method={toggleDiscountModal}
              showText={true}
            />
            <SystemButton
              type="no-form-save"
              method={handleSaveDiscount}
              showText={true}
              btnText={"Save"}
            />
          </div>
        </div>
      </FormModal>

      <FormModal
        moduleName={"Add Customer"}
        modalState={showCustomerModalState}
        toggleFormModal={toggleCustomerModal}
        width="60%"
      >
        <form onSubmit={handleSubmitCustomer}>
          <div className="modal-body">
            <div className="row">
              <label for="name" className="col-sm-3 col-form-label">
                Title *
              </label>

              <div className="col-sm-4">
                <select
                  className="form-control form-control-sm"
                  name="title"
                  id="title"
                  onChange={handleValueChange}
                  value={customerData.title}
                  required
                >
                  <option value="">Select </option>
                  <option value="Mr.">Mr.</option>
                  <option value="Mrs.">Mrs.</option>
                  <option value="Ms.">Ms.</option>
                </select>
              </div>
            </div>
            <div className="row">
              <label htmlFor="name" className="col-sm-3 col-form-label">
                Full Name
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  name="full_name"
                  id="full_name"
                  maxLength="30"
                  className="form-control form-control-sm"
                  value={customerData.full_name}
                  onChange={handleValueChange}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 form-group">
                <label htmlFor="mobile">Mobile Number</label>

                <input
                  type="text"
                  name="mobile_1"
                  id="mobile_1"
                  maxLength="10"
                  minLength="10"
                  className="form-control form-control-sm"
                  value={customerData.mobile_1}
                  onChange={handleValueChange}
                  required
                />
              </div>
              <div className="col-sm-6 form-group">
                <label htmlFor="mobile_2">Mobile Number 2</label>

                <input
                  type="text"
                  name="mobile_2"
                  id="mobile_2"
                  maxLength="10"
                  minLength="10"
                  className="form-control form-control-sm"
                  value={customerData.mobile_2}
                  onChange={handleValueChange}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 form-group">
                <label htmlFor="city"> City</label>
                <input
                  name="city"
                  id="city"
                  className="form-control form-control-sm"
                  value={customerData.city}
                  onChange={handleValueChange}
                />
              </div>
              <div className="col-sm-6 form-group">
                <label htmlFor="district"> District</label>
                <select
                  name="district"
                  id="district"
                  className="form-control form-control-sm"
                  value={customerData.district}
                  onChange={handleValueChange}
                >
                  <option value="">-- Select District --</option>
                  {districtsList.map((district, index) => (
                    <option key={index} value={district.code}>
                      {district.des}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 form-group">
                <label htmlFor="whatsapp">Whatsapp Number</label>

                <input
                  type="text"
                  name="whatsapp"
                  id="whatsapp"
                  maxLength="20"
                  minLength="9"
                  className="form-control form-control-sm"
                  value={customerData.whatsapp}
                  onChange={handleValueChange}
                  required
                />
              </div>
              <div className="col-sm-6 form-group">
                <label htmlFor="branch_id"> Branch</label>
                <select
                  name="branch_id"
                  id="branch_id"
                  className="form-control form-control-sm"
                  value={customerData.branch_id}
                  onChange={handleValueChange}
                >
                  <option value="">-- Select Branch </option>
                  {branchList.map((category, index) => {
                    return (
                      <option key={index} value={category.id}>
                        {category.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="row">
              <label htmlFor="address" className="col-sm-3 col-form-label">
                Address
              </label>
              <div className="col-sm-8">
                <textarea
                  id="address"
                  name="address"
                  className="form-control form-control-sm mb-1"
                  rows="2"
                  maxLength="200"
                  placeholder="Address"
                  value={customerData.address}
                  onChange={handleValueChange}
                ></textarea>
              </div>
            </div>

            <div className="row">
              <label htmlFor="email" className="col-sm-3 col-form-label">
                Email
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  name="email"
                  id="email"
                  maxLength="20"
                  minLength="9"
                  className="form-control form-control-sm"
                  value={customerData.email}
                  onChange={handleValueChange}
                />
              </div>
            </div>

            <div className="row">
              <label htmlFor="nic" className="col-sm-3 col-form-label">
                NIC
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  name="nic"
                  id="nic"
                  maxLength="20"
                  minLength="10"
                  className="form-control form-control-sm"
                  value={customerData.nic}
                  onChange={handleValueChange}
                  required
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <SystemButton
              type={"close"}
              method={toggleCustomerModal}
              showText={true}
            />
            <SystemButton type={"save"} showText={true} />
          </div>
        </form>
      </FormModal>


      <FormModal
      style={{ paddingTop: '50px' }}
      moduleName={"Finalize Sale"}
      modalState={viewPaymentForm}
      toggleFormModal={togglePaymentFormModal}
      width="60%"
    >
      <form onSubmit={handleSubmitSale}>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-10">
              <div className="row">
                <div className="col-md-6 mt-1">
                  <label>Recieved Amount *</label>
                  <input
                    type="text"
                    name="paying_amount"
                    className="form-control numkey"
                    required
                    step="any"
                    value={newData.paying_amount}
                    onChange={handlePaymentValueChange}
                  />
                </div>
                <div className="col-md-6 mt-1">
                  <label>Paying Amount *</label>
                  <input
                    type="text"
                    name="paid_amount"
                    className="form-control numkey"
                    step="any"
                    value={newData.paid_amount}
                    onChange={handlePaymentValueChange}
                  />
                </div>
                <div className="col-md-6 mt-1">
                  <label>Change : </label>
                  <p id="change" className="ml-2">{newData.change}</p>
                </div>
                <div className="col-md-6 mt-1">
                  <label>Paid By</label>
                  <select
                    name="paid_by_id_select"
                    className="form-control selectpicker"
                    value={newData.paid_by_id_select}
                    onChange={handlePaymentValueChange}
                  >
                    <option value="Cash">Cash</option>
                    {/* Add more options here as needed */}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group mt-3">
                  <input
                    type="checkbox"
                    name="tips"
                    id="tips"
                    value="1"
                    checked={newData.tips}
                    onChange={handlePaymentValueChange}
                  />&nbsp;
                  <label>Tip</label>
                  <p>Employee will be added as tips in balance</p>
                </div>
                <div className="col-md-6 form-group">
                  <label>Sale Note</label>
                  <textarea
                    rows="3"
                    className="form-control"
                    name="sale_note"
                    value={newData.sale_note}
                    onChange={handlePaymentValueChange}
                  ></textarea>
                </div>
              </div>
            
            </div>
            <div className="col-md-2 qc" data-initial="1">
              <h4><strong>Quick Cash</strong></h4>
              {[10, 20, 50, 100, 500, 1000,5000].map(amount => (
                <button
                  key={amount}
                  className="btn btn-block btn-primary qc-btn sound-btn"
                  data-amount={amount}
                  type="button"
                  onClick={() => handleQuickCashClick(amount)}
                >
                  {amount}
                </button>
              ))}
               <button
                className="btn btn-block btn-danger qc-btn sound-btn"
                data-amount="0"
                type="button"
                onClick={() => handleQuickCashClick(0)}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <SystemButton
            type={"close"}
            method={togglePaymentFormModal}
            showText={true}
          />
          <SystemButton type={"save"} showText={true} />
        </div>
      </form>
    </FormModal>
    </>
  );

  /* --- End of component renders --- */
};

export default PosHeader;
