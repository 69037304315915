import React, { useState, useEffect } from 'react';
import { api, msg, cookie } from '../../../services';
import { DataTable, FormModal, ListView, SystemButton } from '../../../components';
import moment from 'moment';

const Booking = () => {
  // Module name
  const moduleName = 'Booking';

  /* --- State declarationss --- */

  const [entities, setEntities] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [items, setItems] = useState([]);

  const [newData, setNewData] = useState({
    branch_id: cookie.get("user_branch"),
    user_id: cookie.get("user_id"),
    customer_id: '',
    employee_id: '',
    date: '',
    item_id: '',
    booking_time: '',
    note: '',
    booking_id: '',
  });

  // Form modal state
  const [showModalState, setShowModalState] = useState(false);

  // Data edit state
  const [isEdit, setIsEdit] = useState(false);

  // Data loading status
  const [isLoading, setIsLoading] = useState(false);

  // Selected data
  const [selectedId, setSelectedId] = useState('');

  // List view states
  const dataColumns = [
    {
      header: "Booking Date",
      field: "date",
      showFilterMenu: false,
      searchable: true
    },
    { header: "Item", field: "item_name", showFilterMenu: false,searchable: true },
    { header: "Employee", field: "employee_name", showFilterMenu: false,searchable: true },
  ];
  let dataRows = [];

  /* --- End of state declarations --- */

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // if (showModalState === false) {
    //   resetForm();
    // }
  }, [showModalState]);

  /* --- Component functions --- */

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const response = await api.get('booking');

    
      if (response.error) {
        Object.values(response.error).forEach((err) => {
          msg.error(err[0]);
        });
        return;
      }
      setCustomers(response.data.customers);
      setEmployees(response.data.employees);
      setItems(response.data.items);

      response.data.bookings.map((entity) => {
        return dataRows.push({
          id: entity.id,
          employee_id: entity.employee_id,
          item_id:entity.item_id,
          item_name:entity.item.item_name,
        branch_id: cookie.get("user_branch"),
        user_id: cookie.get("user_id"),
        customer_id: entity.employee_id,
        employee_name:entity.employee.name,
        date:entity.booking_date,
        note: entity.note,
         
        });
      });
      setEntities(dataRows);

    } catch (error) {
      console.log(error);
      msg.error('Unable to fetch data!');
    } finally {
      setIsLoading(false);
    }
  };



  const handleValueChange = (e) => {
    const targetInput = e.target;
    const inputName = targetInput.name;
    // const inputValue = targetInput.value;
    const inputValue =
      targetInput.type === 'checkbox' ? targetInput.checked : targetInput.value;

    setNewData({
      ...newData,
      [inputName]: inputValue,
    });
  };

  const editRow = (dataObj) => {
    console.log(dataObj);
    setShowModalState(true);
    setNewData({
      code: dataObj.code,
      name: dataObj.name,
    });

    setIsEdit(true);
    setSelectedId(dataObj.id);
  };

  const toggleFormModal = () => {
    setShowModalState(!showModalState);
  };

  const handleSubmit = async () => {
    await save();
  };

  const save = async () => {
    if (isEdit === false) {
      try {
        const response = await api.post('booking').values(newData);
        if (response.status == 200 && response.data.status == 200) {
          msg.success(response.data.message);
          resetForm();
          fetchData();
          setShowModalState(false);
        } else if (response.status == 200 && response.data.status == 500) {
          msg.error(response.data.message);
        } else if (response.status == 200 && response.data.status == 400) {
          Object.values(response.data.message).forEach((err) => {
            msg.error(err[0]);
          });
        } else if (response.data.status == 400) {
          Object.values(response.message).forEach((err) => {
            msg.error(err[0]);
          });
        } else if (response.data.status == 520) {
          msg.warning(response.data.message);
        }
      } catch (error) {
        msg.error(error);
        return console.log(error);
      }
    } else {
      try {
        const response = await api
          .update(`add-brand/${selectedId}/update`)
          .values(newData);
          if (response.status == 200 && response.data.status == 200) {
            msg.success(response.data.message);
            resetForm();
            fetchData();
            setIsEdit(false);
            setSelectedId('');
            setShowModalState(false);
          } else if (response.status == 200 && response.data.status == 500) {
            msg.error(response.data.message);
          } else if (response.status == 200 && response.data.status == 400) {
            Object.values(response.data.message).forEach((err) => {
              msg.error(err[0]);
            });
          } else if (response.data.status == 400) {
            Object.values(response.message).forEach((err) => {
              msg.error(err[0]);
            });
          } else if (response.data.status == 520) {
            msg.warning(response.data.message);
          }
      } catch (error) {
        msg.error(error);
        return console.error(error);
      } 
    }
  };
  const deleteRow = async (dataObj) => {
    try {
      const response = await api.delete(`booking/${dataObj.id}`);
      if (response.status == 200 && response.data.status == 200) {
        msg.success(response.data.message);
        fetchData();
      } else if (response.status == 200 && response.data.status == 500) {
        msg.error(response.data.message);
      } else if (response.status == 200 && response.data.status == 400) {
        Object.values(response.data.message).forEach((err) => {
          msg.error(err[0]);
        });
      } else if (response.data.status == 400) {
        Object.values(response.message).forEach((err) => {
          msg.error(err[0]);
        });
      } else if (response.data.status == 520) {
        msg.warning(response.data.message);
      }
    } catch (error) {
      msg.error(error);
    } 
  };

  const resetForm = () => {
    setNewData({
      ...newData,
      branch_id: cookie.get("user_branch"),
    user_id: cookie.get("user_id"),
    customer_id: '',
    employee_id: '',
    date: '',
    item_id: '',
    booking_time: '',
    note: '',
    booking_id: '',
    });

    setIsEdit(false);
  };



  /* --- End of component functions --- */

  /* --- Component renders --- */

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{moduleName}</h1>
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header border-0">
                <div className="col-md-12 d-block">
                  <SystemButton
                    type="add-new"
                    classes={'btn-sm btn  btn-success float-sm-right'}
                    method={toggleFormModal}
                    showText
                    btnText="Create Booking"
                  />
                </div>
              </div>
              <div className="card-body">
              <DataTable
              columns={dataColumns}
      dataList={entities}
      // edit={false}
      deletedata={deleteRow}
      canEdit={false}  // or false based on your condition
  canDelete={true}
      />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* End of form modal componenet */}
      <FormModal
        moduleName={moduleName}
        modalState={showModalState}
        toggleFormModal={toggleFormModal}
        width="50%"
      >
       <form onSubmit={handleSubmit} className="compactForm">
        <div className="modal-body">
          <div className="row">
            <div className="col">
              <label htmlFor="customer_id">Customer</label><small className="text-danger">*</small>
              <select
                className="form-control select2"
                name="customer_id"
                id="customer_id"
                required
                value={newData.customer_id}
                onChange={handleValueChange}
              >
                <option value="">Select</option>
                {customers.map((customer) => (
                <option key={customer.id} value={customer.id}>{customer.full_name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="employee_id">Employee</label><small className="text-danger">*</small>
              <select
                className="form-control select2"
                name="employee_id"
                id="employee_id"
                required
                value={newData.employee_id}
                onChange={handleValueChange}
              >
                <option value="">Select</option>
                {employees.map((employee) => (
                  <option key={employee.id} value={employee.id}>{`${employee.first_name} ${employee.last_name}`}</option>
                ))}
              </select>
            </div>
            <div className="col">
              <label htmlFor="date">Date</label><small className="text-danger">*</small>
              <input
                type="date"
                name="date"
                className="form-control form-control-sm"
                id="date"
                required
                value={newData.date}
                onChange={handleValueChange}
              />
              <small className="text-danger err" id="name-err"></small>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="item_id">Items</label><small className="text-danger">*</small>
              <select
                className="form-control select2"
                name="item_id"
                id="item_id"
                required
                value={newData.item_id}
                onChange={handleValueChange}
              >
                <option value="">Select</option>
                {items.map((service) => (
                  <option key={service.id} value={service.id}>{service.item_name}</option>
                ))}
              </select>
            </div>
            {/* <div className="col">
              <label htmlFor="booking_time">Time</label><small className="text-danger">*</small>
              <select
                className="form-control select2"
                name="booking_time"
                id="booking_time"
                required
                value={newData.booking_time}
                onChange={handleValueChange}
              >
                <option value="">Select Service First</option>
                
              </select>
            </div> */}
            <div className="col">
              <label htmlFor="note">Note</label><small className="text-danger">*</small>
              <textarea
                className="form-control"
                id="note"
                name="note"
                value={newData.note}
                onChange={handleValueChange}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <SystemButton type={'close'} method={toggleFormModal} showText={true} />
          <SystemButton type="no-form-save" method={handleSubmit} showText={true} />
        </div>
      </form>
      </FormModal>
     

    </>
  );

  /* --- End of component renders --- */
};

export default Booking;
