import React from 'react';
import { cookie } from '../../services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';

function Reports() {
  return (
    <div>
      {/* Dedigama testing server */}
      <form
        action={`${process.env.REACT_APP_DEFAULT_PATH}/reportHome/${cookie.get('user_id')}`}
        // action={`http://123.231.16.203/reportHome/${cookie.get('user_id')}`} 
        // method="get"
        target="_blank"
      >
        <button
          type="submit"
          className="btn btn-sm rounded-0"
          style={{
            backgroundColor: '#2867FD',
            color: '#fff',
            padding: '3px 10px 3px 10px',
          }}
        >
          <span>
            <FontAwesomeIcon icon={faFileAlt} color="white" />
          </span>
          &nbsp; Reports
        </button>
        {/* <input type="submit" value="Reports" className="btn btn" /> */}
      </form>
    </div>
  );
}

export default Reports;
