import React, { useState, useEffect } from 'react';
import { api, auth, msg } from '../services';
import { useHistory } from 'react-router-dom';
import { loginLogo, loginMain } from '../assets';
import {
  mobileVendor,
  mobileModel,
  isMobile,
  browserName,
  browserVersion,
  osName,
  osVersion,
} from 'react-device-detect';
import { ToastContainer } from 'react-toastify';

const Login = () => {
  const [credentials, setCredentials] = useState({
    username: '',
    password: '',
    branch: ''
  });

  const [branchList, setBranchList] = useState([]);

  const [currentBranch, setCurrentBranch] = useState({
    id: '', //cookie.get('user_branch'),
    code: '',
    name: '', //cookie.get('user_branch_name'),
  });

  useEffect(() => {
    // fetchData();
  }, []);

  // const fetchData = async () => {
  //   try {
  //     // setIsLoading(true);
  //     const response = await api.get(`all-branches`);

  //     setBranchList(response.data);

  //     setCredentials({
  //       ...credentials,
  //       branch: response.data[0].bc_no,
  //     });
  //   } catch (error) {
  //     console.log(error);
  //     return msg.error('Unable to fetch branches! 🙀');
  //   } finally {
  //     // setIsLoading(false);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await auth.doUserLogin(credentials);
    console.log(response);
    if (response.message) {
      msg.error(response.message);
    } else {
      await auth.handleLoginSuccess(response);

      window.location.reload();
    }
  };

  const handleValueChange = (e) => {
    const targetInput = e.target;
    const inputName = targetInput.name;
    const inputValue = targetInput.value;

    setCredentials({
      ...credentials,
      [inputName]: inputValue,
    });
  };

  return (
    <div className="hold-transition login-page">
       <ToastContainer
        position="botton-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    <div className="login-box">
    
      <div className="card">
      <img src={loginLogo} alt="logo" className="logo" />
        <div className="card-body login-card-body">
        <p class="login-box-msg"> <b>GAMAGE JEWELLERY SYSTEM<font color="#f05138">.</font></b><br/>
        Sign In to  System</p>
          <form onSubmit={handleSubmit}>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Username"
                name="username"
                value={credentials.username}
                onChange={handleValueChange}
                required
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-user"></span>
                </div>
              </div>
            </div>
            <div className="input-group mb-3">
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                name="password"
                value={credentials.password}
                onChange={handleValueChange}
                required
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-lock"></span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-8">
                <div className="icheck-primary">
                  {/* You can add Remember Me functionality here */}
                  {/* <input type="checkbox" id="remember" />
                  <label htmlFor="remember">Remember Me</label> */}
                </div>
              </div>
              <div className="col-4">
                <button type="submit" className="btn btn-primary btn-block">Sign In</button>
              </div>
            </div>
          </form>

          {/* <p className="mb-1">
            <a href="#">I forgot my password</a>
          </p> */}
        </div>
      </div>
    </div>
  </div>
  );
};

export default Login;
