import {
  Dashboard,
  ItemCategories,
  Items,
  Buyers,
  Customers,
  Vendors,
  Employees,
  Stores,
  Design,
  ArticleSize,
  TagCreation,
  Branches,
  PurchaseOrders,
  Purchases,
  SupplierPayment,
  TagChange,
  DefaultAccount,
  PurchaseReturn,
  OpeningStock,
  Currency,
  JewellerySearch,
  GoldRate,
  TagTransfer,
  Metal,
  Touch,
  Color,
  Brand,
  Saloon,
  PaymentVoucher,
  ReceiptForm,
  ReportView,
  IssueJewelleryForRepair,
  RecivedFromMaker,
  IssueForCustomer,
  Calender,
  Booking,
  GoldSmithGoldIssue,
  GoldSmithGoldRecived,
  GoldMelt,
  CustomerStatement,
  Pos,
  RolePermissions,
} from '../views';

import { Users, Roles } from '../auth';

import { api, cookie } from '../services';

const componentsList = [
  {
    name: 'Dashboard',
    route: '/home', 
    value: Dashboard,
  },
  {
    name: 'Pos',
    route: '/pos',
    value: Pos,
  },
  {
    name: 'Items',
    route: '/items',
    value: Items,
  },
  {
    name: 'Booking',
    route: '/booking',
    value: Booking,
  },
  {
    name: 'Calender',
    route: '/calender',
    value: Calender,
  },
  {
    name: 'Branches',
    route: '/branches',
    value: Branches,
  },
  {
    name: 'Customers',
    route: '/customers',
    value: Customers,
  },
  // {
  //   name: 'Buyers',
  //   route: '/buyers',
  //   value: Buyers,
  // },
  {
    name: 'Vendors',
    route: '/vendors',
    value: Vendors,
  },
  {
    name: 'Employees',
    route: '/employees',
    value: Employees,
  },

  // {
  //   name: 'GoldTypes',
  //   route: '/gold-types',
  //   value: GoldTypes,
  // },
  // {
  //   name: 'Currency',
  //   route: '/currencies',
  //   value: Currency,
  // },
  // {
  //   name: 'Expenses',
  //   route: '/expenses',
  //   value: Expenses,
  // },
  // {
  //   name: 'CashFundTransfers',
  //   route: '/cash-fund-transfers',
  //   value: CashFundTransfers,
  // },
  // {
  //   name: 'IssueCheques',
  //   route: '/issue-cheques',
  //   value: IssueCheques,
  // },
  // {
  //   name: 'ReceiveCheques',
  //   route: '/receive-cheques',
  //   value: ReceiveCheques,
  // },
  {
    name: 'Users',
    route: '/users',
    value: Users,
  },
  // {
  //   name: 'Roles',
  //   route: '/roles',
  //   value: Roles,
  // },
  // {
  //   name: 'GeneralReceipt',
  //   route: '/general-receipt',
  //   value: GeneralReceipt,
  // },
  // {
  //   name: 'GeneralVoucher',
  //   route: '/general-voucher',
  //   value: GeneralVoucher,
  // },
  // {
  //   name: 'DefaultAccount',
  //   route: '/default-accounts',
  //   value: DefaultAccount,
  // },
  // {
  //   name: 'Vendors',
  //   route: '/vendors',
  //   value: Vendors,
  // },
 
  // {
  //   name: 'Stores',
  //   route: '/stores',
  //   value: Stores,
  // },
  // {
  //   name: 'Design',
  //   route: '/design',
  //   value: Design,
  // },
  // {
  //   name: 'ArticleSize',
  //   route: '/article-sizes',
  //   value: ArticleSize,
  // },
  // {
  //   name: 'PurchaseOrders',
  //   route: '/purchase-orders',
  //   value: PurchaseOrders,
  // },
  {
    name: 'Purchases',
    route: '/purchases',
    value: Purchases,
  },
  // {
  //   name: 'Purchases',
  //   route: '/purchases/:nno/:bc_no',
  //   value: Purchases,
  // },
  // {
  //   name: 'TagCreation',
  //   route: '/tag-creations',
  //   value: TagCreation,
  // },

  // {
  //   name: 'SalesOrder',
  //   route: '/sales-orders',
  //   value: SalesOrder,
  // },

  // {
  //   name: 'Invoice',
  //   route: '/invoices',
  //   value: Invoice,
  // },

  // {
  //   name: 'AdvancePayment',
  //   route: '/advance-payments',
  //   value: AdvancePayment,
  // },
  // {
  //   name: 'CashRefund',
  //   route: '/advance-refunds',
  //   value: AdvanceRefund,
  // },
  // {
  //   name: 'CustomerPayment',
  //   route: '/customer-payments',
  //   value: CustomerPayment,
  // },
  // {
  //   name: 'SupplierPayment',
  //   route: '/supplier-payments',
  //   value: SupplierPayment,
  // },

  // {
  //   name: 'Supplier',
  //   route: '/supplier',
  //   value: Supplier,
  // },
  // {
  //   name: 'SalesReturn',
  //   route: '/sales-returns',
  //   value: SalesReturn,
  // },
  // {
  //   name: 'PurchaseReturn',
  //   route: '/purchase-returns',
  //   value: PurchaseReturn,
  // },
  // {
  //   name: 'OpeningStock',
  //   route: '/opening-stock',
  //   value: OpeningStock,
  // },
  // {
  //   name: 'JewellerySearch',
  //   route: '/jewellery-search',
  //   value: JewellerySearch,
  // },
  // {
  //   name: 'GoldRate',
  //   route: '/gold-rate',
  //   value: GoldRate,
  // },
  // {
  //   name: 'TagTransfer',
  //   route: '/tag-transfer',
  //   value: TagTransfer,
  // },
  // {
  //   name: 'Metal',
  //   route: '/metal',
  //   value: Metal,
  // },
  // {
  //   name: 'Touch',
  //   route: '/touch',
  //   value: Touch,
  // },
  // {
  //   name: 'Color',
  //   route: '/color',
  //   value: Color,
  // },
  {
    name: 'Brand',
    route: '/brand',
    value: Brand,
  },  
  {
    name: 'Saloon',
    route: '/saloon',
    value: Saloon,
  },
  // {
  //   name: 'PaymentVoucher',
  //   route: '/payment-voucher/:nno/:bc_no',
  //   value: PaymentVoucher,
  // },
  // {
  //   name: 'ReceiptForm',
  //   route: '/receipt/:nno/:bc_no',
  //   value: ReceiptForm,
  // },
  // {
  //   name: 'CustomerRepair',
  //   route: '/customer-repair',
  //   value: CustomerRepair,
  // },
  // {
  //   name: 'IssueJewelleryForRepair',
  //   route: '/issue-jewellery-repair',
  //   value: IssueJewelleryForRepair,
  // },
  // {
  //   name: 'RecivedFromMaker',
  //   route: '/recived-from-maker',
  //   value: RecivedFromMaker,
  // },
  // {
  //   name: 'IssueForCustomer',
  //   route: '/issue-for-customer',
  //   value: IssueForCustomer,
  // },
  // {
  //   name: 'ForfeitPurchase',
  //   route: '/forfeit-purchase',
  //   value: ForfeitPurchase,
  // },
  // {
  //   name: 'ResellRepair',
  //   route: '/resell-repair',
  //   value: ResellRepair,
  // },
  // {
  //   name: 'GoldSmithGoldIssue',
  //   route: '/supplier-issue-gold',
  //   value: GoldSmithGoldIssue,
  // },
  // {
  //   name: 'GoldSmithGoldRecived',
  //   route: '/supplier-recived-gold',
  //   value: GoldSmithGoldRecived,
  // },
  // {
  //   name: 'GoldMelt',
  //   route: '/gold-melt',
  //   value: GoldMelt,
  // },
  // {
  //   name: 'CustomerStatement',
  //   route: '/customer-statement',
  //   value: CustomerStatement,
  // },

  // {
  //   name: 'RolePermissions',
  //   route: '/role-permissions',
  //   value: RolePermissions,
  // },
];

let routesList = [];

let tempComponentName = '';

class Routes {
  async routes() {
   
  
    // debugger
    componentsList.forEach((component) => {
      routesList.push({
        name: component.name,
        pathURL: component.route,
        componentName: component.value,
        // group: route.group,
        // order: route.order,
        // level: route.level,
      });
    });
    
    return Promise.resolve(routesList);
   
  }
}

export default new Routes();
