import React, { useState, useEffect } from 'react';
import { api, msg, cookie } from '../../../services';
import { DataTable, FormModal, ListView, SystemButton } from '../../../components';
import moment from 'moment';

const Employee = () => {
  // Module name
  const moduleName = 'Employee';

  /* --- State declarationss --- */

  const [entities, setEntities] = useState([]);

  const [users, setUsers] = useState([]);

  const [newData, setNewData] = useState({
    id: '',
    name: '',
    is_salesman: 0,
    user_id: cookie.get('user_id'),
    bc_no: cookie.get('user_branch'),
    salesman_user: '',
    date:moment().format(`YYYY-MM-DD`)
  });

  // Data loading status
  const [isLoading, setIsLoading] = useState(false);

  // Form modal state
  const [showModalState, setShowModalState] = useState(false);

  // Data edit state
  const [isEdit, setIsEdit] = useState(false);
  const [branchList, setBranchList] = useState([]);


  // Selected data
  const [selectedId, setSelectedId] = useState('');

  // List view states
  let dataColumns = [
    { header: "NIC", field: "nic", searchable: true },
    { header: "Name", field: "full_name", searchable: true },
    { header: "Address", field: "address", searchable: true },
    { header: "Email", field: "email", searchable: true },
    { header: "Mobile", field: "mobile_1", searchable: true },
  ];

  let dataRows = [];
  /* --- End of state declarations --- */

  useEffect(() => {
    fetchData();
    fetchBranches();
  }, []);

  useEffect(() => {
    if (showModalState === false) {
      resetForm();
    }
  }, [showModalState]);

  /* --- Component functions --- */



  const fetchData = async () => {
    dataRows = [];
    try {
      setIsLoading(true);

      const response = await api.get('employees');
      if (response.error) {
        Object.values(response.error).forEach((err) => {
          msg.error(err[0]);
        });
        return;
      }
      response.data.map((entity) => {
        return dataRows.push({
          id: entity.id,
          nic: entity.nic,
          city: entity.city,
          email:entity.email,
          whatsapp: entity.whatsapp,
          district: entity.district,
          branch_id: entity.branch_id,
          full_name: entity.full_name,
          title: entity.title,
          address: entity.address ? entity.address : "",
          address2: entity.address2 ? entity.address2 : "",
          mobile_1: entity.mobile_1 ? entity.mobile_1 : "",
          mobile_2: entity.mobile_2 ? entity.mobile_2 : "",
          date:entity.date
        });
      });

      setEntities(dataRows);

      setIsLoading(false);
    } catch (error) {
      msg.error("Error data Load!");
    }
     // return console.log(error);
    
  };

 

  const fetchBranches = async () => {
    try {
      const response = await api.get("all-branches");

      if (response.error) {
        Object.values(response.error).forEach((err) => {
          msg.error(err[0]);
        });
        return;
      } else {
        setBranchList(response.data);
      }
    } catch (error) {
      return console.log(error);
    }
  };
  

  const handleValueChange = (e) => {
    const targetInput = e.target;
    const inputName = targetInput.name;
    const inputValue =
      targetInput.type === 'checkbox' ? targetInput.checked : targetInput.value;

    setNewData({
      ...newData,
      [inputName]: inputValue,
    });
  };

  const editRow = async (dataObj) => {
    console.log(dataObj);
    setShowModalState(true);
    setNewData({
      ...newData,
      title: dataObj.title,
      full_name: dataObj.full_name,

      nic: dataObj.nic,
      address: dataObj.address,
      mobile_1: dataObj.mobile_1,
      mobile_2: dataObj.mobile_2,
      email: dataObj.email,
      branch_id: dataObj.branch_id,
      date:dataObj.date,
      whatsapp: dataObj.whatsapp,
    });

    setIsEdit(true);
    setSelectedId(dataObj.id);
  };

  const toggleFormModal = () => {
    setShowModalState(!showModalState);
  
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    await save();

  };

  const save = async () => {
    if (isEdit === false) {
      try {
        const response = await api.post('employees').values(newData);

        if (response.status == 200 && response.data.status == 200) {
          msg.success(response.data.message);
          resetForm();
          fetchData();

          setIsEdit(false);
          setSelectedId('');
          setShowModalState(false);
        } else if (response.status == 200 && response.data.status == 500) {
          msg.error(response.data.message);
        } else if (response.status == 200 && response.data.status == 400) {
          Object.values(response.data.message).forEach((err) => {
            msg.error(err[0]);
          });
          console.log('reee');
          msg.error(response.data.message);
        } else if (response.data.status == 400) {
          // Object.values(response.message).forEach((err) => {
          //   msg.error(err[0]);
          // });
          msg.error(response.data.message);
        }
      } catch (error) {
        return console.log(error);
      }
      // } finally {
      //   setShowModalState(false);
      // }
    } else {
      try {
        const response = await api
          .update(`employees/${selectedId}/update`)
          .values(newData);

        if (response.status == 200 && response.data.status == 200) {
          msg.success(response.data.message);
          resetForm();
          fetchData();

          setIsEdit(false);
          setSelectedId('');
          setShowModalState(false);
        } else if (response.status == 200 && response.data.status == 500) {
          msg.error(response.data.message);
        } else if (response.status == 200 && response.data.status == 400) {
          Object.values(response.data.message).forEach((err) => {
            msg.error(err[0]);
          });
          console.log('reee');
          msg.error(response.data.message);
        } else if (response.data.status == 400) {
          // Object.values(response.message).forEach((err) => {
          //   msg.error(err[0]);
          // });
          msg.error(response.data.message);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const deleteRow = async (dataObj) => {
    try {
      const response = await api.delete(`employees/${dataObj.id}`);
      if (response.status == 200 && response.data.status == 200) {
        msg.success(response.data.message);
        fetchData();
      } else if (response.status == 200 && response.data.status == 500) {
        msg.error(response.data.message);
      } else if (response.status == 200 && response.data.status == 400) {
        Object.values(response.data.message).forEach((err) => {
          msg.error(err[0]);
        });
      } else if (response.data.status == 400) {
        Object.values(response.message).forEach((err) => {
          msg.error(err[0]);
        });
      } else if (response.data.status == 520) {
        msg.warning(response.data.message);
      }
    } catch (error) {
      return console.log(error);
    } 
  };


  const resetForm = () => {
    setNewData({
      // id: '',
      name: '',
      is_salesman: 0,
      user_id: cookie.get('user_id'),
      bc_no: cookie.get('user_branch'),
      salesman_user: '',
      date:moment().format(`YYYY-MM-DD`)
    });
    setIsEdit(false);
  };

  const resetSearch = () => {
    setEntities([]);

    fetchData();
  };

  /* --- End of component functions --- */

  /* --- Component renders --- */

  return (
    <>
       <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{moduleName}</h1>
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header border-0">
                <div className="col-md-12 d-block">
                  <SystemButton
                    type="add-new"
                    classes={"btn-sm btn  btn-success float-sm-right"}
                    method={toggleFormModal}
                    showText
                    btnText="Add Employee"
                  />
                </div>
              </div>
              <div className="card-body">
                <DataTable
                  columns={dataColumns}
                  dataList={entities}
                  edit={editRow}
                  deletedata={deleteRow}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <FormModal
        moduleName={moduleName}
        modalState={showModalState}
        toggleFormModal={toggleFormModal}
        width="60%"
      >
        <form onSubmit={handleSubmit}>
          <div className="modal-body">
            <div className="row">
              <label for="name" className="col-sm-3 col-form-label">
                Title *
              </label>

              <div className="col-sm-4">
                <select
                  className="form-control form-control-sm"
                  name="title"
                  id="title"
                  onChange={handleValueChange}
                  value={newData.title}
                  required
                >
                  <option value="">Select </option>
                  <option value="Mr.">Mr.</option>
                  <option value="Mrs.">Mrs.</option>
                  <option value="Ms.">Ms.</option>
                </select>
              </div>
            </div>
            <div className="row">
              <label htmlFor="name" className="col-sm-3 col-form-label">
                Full Name
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  name="full_name"
                  id="full_name"
                  maxLength="30"
                  className="form-control form-control-sm"
                  value={newData.full_name}
                  onChange={handleValueChange}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 form-group">
                <label htmlFor="mobile">Mobile Number</label>

                <input
                  type="text"
                  name="mobile_1"
                  id="mobile_1"
                  maxLength="10"
                  minLength="10"
                  className="form-control form-control-sm"
                  value={newData.mobile_1}
                  onChange={handleValueChange}
                  required
                />
              </div>
              <div className="col-sm-6 form-group">
                <label htmlFor="mobile_2">Mobile Number 2</label>

                <input
                  type="text"
                  name="mobile_2"
                  id="mobile_2"
                  maxLength="10"
                  minLength="10"
                  className="form-control form-control-sm"
                  value={newData.mobile_2}
                  onChange={handleValueChange}
                  
                />
              </div>
            </div>
            
            <div className="row">
              <div className="col-sm-6 form-group">
                <label htmlFor="whatsapp">Whatsapp Number</label>

                <input
                  type="text"
                  name="whatsapp"
                  id="whatsapp"
                  maxLength="20"
                  minLength="9"
                  className="form-control form-control-sm"
                  value={newData.whatsapp}
                  onChange={handleValueChange}
                  
                />
              </div>
              <div className="col-sm-6 form-group">
                <label htmlFor="branch_id"> Branch</label>
                <select
                  name="branch_id"
                  id="branch_id"
                  className="form-control form-control-sm"
                  value={newData.branch_id}
                  onChange={handleValueChange}
                >
                  <option value="">-- Select Branch </option>
                  {branchList.map((category, index) => {
                    return (
                      <option key={index} value={category.id}>
                        {category.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="row">
              <label htmlFor="address" className="col-sm-3 col-form-label">
                Address
              </label>
              <div className="col-sm-8">
                <textarea
                  id="address"
                  name="address"
                  className="form-control form-control-sm mb-1"
                  rows="2"
                  maxLength="200"
                  placeholder="Address"
                  value={newData.address}
                  onChange={handleValueChange}
                ></textarea>
              </div>
            </div>

            <div className="row">
              <label htmlFor="email" className="col-sm-3 col-form-label">
                Email
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  name="email"
                  id="email"
                  maxLength="20"
                  minLength="9"
                  className="form-control form-control-sm"
                  value={newData.email}
                  onChange={handleValueChange}
                />
              </div>
            </div>

            <div className="row">
              <label htmlFor="nic" className="col-sm-3 col-form-label">
                NIC
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  name="nic"
                  id="nic"
                  maxLength="20"
                  minLength="10"
                  className="form-control form-control-sm"
                  value={newData.nic}
                  onChange={handleValueChange}
                  required
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <SystemButton
              type={"close"}
              method={toggleFormModal}
              showText={true}
            />
            <SystemButton type={"save"} showText={true} />
          </div>
        </form>
      </FormModal>
    </>
  );

  /* --- End of component renders --- */
};

export default Employee;
