import React, { useState, useEffect } from "react";
import { api, msg, cookie } from "../../../services";
import { DataTable, FormModal, ListView, SystemButton } from "../../../components";



const Branches = () => {
  // Module name
  const moduleName = "Branches";

  /* --- State declarationss --- */

  const [entities, setEntities] = useState([]);

  const [regionalOffices, setRegionalOffices] = useState([]);

  const [newData, setNewData] = useState({
    code: "",
    name: "",
    address: "",
    telephone: "",
    fax: "",
    email: "",
    grade: "",
    is_headoffice: 0,
    has_unique_int: 0,
    regional_office_id: 0,
    user_id: cookie.get("user_id"),
  });

  // Form modal state
  const [showModalState, setShowModalState] = useState(false);

  // Data edit state
  const [isEdit, setIsEdit] = useState(false);

  // Data loading status
  const [isLoading, setIsLoading] = useState(false);

  // Selected data
  const [selectedId, setSelectedId] = useState("");

  // Head office branch
  const [headOfficeId, setHeadOfficeId] = useState();

  // List view states
  const dataColumns = [
    {
      header: "Branch Code",
      field: "code",
      showFilterMenu: false,
      searchable: true
    },
    { header: "Name", field: "name", showFilterMenu: false,searchable: true },
    { header: "Address", field: "address", showFilterMenu: false,searchable: true },
    { header: "Telephone", field: "telephone", showFilterMenu: false,searchable: true },
    { header: "Fax", field: "fax", showFilterMenu: false ,searchable: false},
    { header: "E-mail", field: "email", showFilterMenu: false,searchable: false },
  ];
  let dataRows = [];

  /* --- End of state declarations --- */

  useEffect(() => {
    fetchData();

  
  }, []);

  useEffect(() => {
    if (showModalState === false) {
      resetForm();
    }
  }, [showModalState]);

  /* --- Component functions --- */

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const response = await api.get("branches");

      console.log(response);

      if (response.error) {
        Object.values(response.error).forEach((err) => {
          msg.error(err[0]);
        });
        return;
      }


      response.data.entities.map((entity) => {

        if (entity.id !== 0) {
          dataRows.push({
            id: entity.id,
            code: entity.code,
            name: entity.name,
            address: entity.address,
            telephone: entity.telephone,
            fax: entity.fax,
            email: entity.email,
          });
        }
      });

      setEntities(dataRows);
       setIsLoading(false);
    } catch (error) {
      return msg.error("Unable to fetch data!");
    }
  };

 
  const handleValueChange = (e) => {
    const targetInput = e.target;
    const inputName = targetInput.name;
    // const inputValue = targetInput.value;
    const inputValue =
      inputName === "is_headoffice" || inputName === "has_unique_int"
        ? targetInput.checked
        : targetInput.value;

    setNewData({
      ...newData,
      [inputName]: inputValue,
    });
  };

  const editRow = (dataObj) => {
    setShowModalState(true);

    setNewData({
      code: dataObj.code,
      name: dataObj.name,
      address: dataObj.address,
      telephone: dataObj.telephone,
      fax: dataObj.fax,
      email: dataObj.email,
      grade: dataObj.grade,
      is_headoffice: dataObj.is_headoffice,
      has_unique_int: dataObj.has_unique_int,
      regional_office_id: dataObj.regional_office_id,
    });

    setIsEdit(true);
    setSelectedId(dataObj.id);
  };

  const toggleFormModal = () => {
    setShowModalState(!showModalState);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    await save();

  };

  const save = async () => {
    if (isEdit === false) {
      try {
        const response = await api.post("branches").values(newData);
        if (response.status == 200 && response.data.status == 200) {
          msg.success(response.data.message);
          resetForm();
          fetchData();
          setIsEdit(false);
          setSelectedId('');
          setShowModalState(false);
        } else if (response.status == 200 && response.data.status == 500) {
          msg.error(response.data.message);
        } else if (response.status == 200 && response.data.status == 400) {
          Object.values(response.data.message).forEach((err) => {
            msg.error(err[0]);
          });
        } else if (response.data.status == 400) {
          Object.values(response.message).forEach((err) => {
            msg.error(err[0]);
          });
        } else if (response.data.status == 520) {
          msg.warning(response.data.message);
        }
      } catch (error) {
        msg.error(error);
       
      } 
    } else {
      try {
        const response = await api
          .update(`branches/${selectedId}/update`)
          .values(newData);

          if (response.status == 200 && response.data.status == 200) {
            msg.success(response.data.message);
            resetForm();
            fetchData();
            setIsEdit(false);
            setSelectedId('');
            setShowModalState(false);
          } else if (response.status == 200 && response.data.status == 500) {
            msg.error(response.data.message);
          } else if (response.status == 200 && response.data.status == 400) {
            Object.values(response.data.message).forEach((err) => {
              msg.error(err[0]);
            });
          } else if (response.data.status == 400) {
            Object.values(response.message).forEach((err) => {
              msg.error(err[0]);
            });
          } else if (response.data.status == 520) {
            msg.warning(response.data.message);
          }
      } catch (error) {
        msg.error(error);
        return console.error(error);
      } 
    }
  };

  const resetForm = () => {
    setNewData({
      code: "",
      name: "",
      address: "",
      telephone: "",
      fax: "",
      email: "",
      grade: "",
      user_id: cookie.get("user_id"),
    });
    setIsEdit(false);
  };

  const deleteRow = async (dataObj) => {
    try {
      const response = await api.delete(`branches/${dataObj.id}`);
      if (response.status == 200 && response.data.status == 200) {
        msg.success(response.data.message);
        fetchData();
      } else if (response.status == 200 && response.data.status == 500) {
        msg.error(response.data.message);
      } else if (response.status == 200 && response.data.status == 400) {
        Object.values(response.data.message).forEach((err) => {
          msg.error(err[0]);
        });
      } else if (response.data.status == 400) {
        Object.values(response.message).forEach((err) => {
          msg.error(err[0]);
        });
      } else if (response.data.status == 520) {
        msg.warning(response.data.message);
      }
    } catch (error) {
      msg.error(error);
    } 
  };


  /* --- End of component functions --- */

  /* --- Component renders --- */

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{moduleName}</h1>
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header border-0">
                <div className="col-md-12 d-block">
                  <SystemButton
                    type="add-new"
                    classes={'btn-sm btn  btn-success float-sm-right'}
                    method={toggleFormModal}
                    showText
                    btnText="Add Branch"
                  />
                </div>
              </div>
              <div className="card-body">
              <DataTable
              columns={dataColumns}
      dataList={entities}
      edit={editRow}
      deletedata={deleteRow}
      />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Form modal componenet */}
      <FormModal
        moduleName={moduleName}
        modalState={showModalState}
        toggleFormModal={toggleFormModal}
        width="60%"
      >
        <form onSubmit={handleSubmit}>
          <div className="modal-body">
            <div className="row">
              <label htmlFor="name" className="col-sm-3 col-form-label">
                Name
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="form-control form-control-sm"
                  value={newData.name}
                  onChange={handleValueChange}
                />
              </div>
            </div>

            <div className="row">
              <label htmlFor="code" className="col-sm-3 col-form-label">
                Code
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  name="code"
                  id="code"
                  className="form-control form-control-sm"
                  value={newData.code}
                  onChange={handleValueChange}
                />
              </div>
            </div>
            <div className="row">
              <label htmlFor="email" className="col-sm-3 col-form-label">
                Email
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  name="email"
                  id="email"
                  className="form-control form-control-sm"
                  value={newData.email}
                  onChange={handleValueChange}
                />
              </div>
            </div>
            <div className="row">
              <label htmlFor="telephone" className="col-sm-3 col-form-label">
                Telephone
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  name="telephone"
                  id="telephone"
                  maxLength="10"
                  minLength="9"
                  className="form-control form-control-sm"
                  value={newData.telephone}
                  onChange={handleValueChange}
                />
              </div>
            </div>
            <div className="row">
              <label htmlFor="fax" className="col-sm-3 col-form-label">
                Fax
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  name="fax"
                  id="fax"
                  maxLength="20"
                  minLength="9"
                  className="form-control form-control-sm"
                  value={newData.fax}
                  onChange={handleValueChange}
                />
              </div>
            </div>

            <div className="row">
              <label htmlFor="address" className="col-sm-3 col-form-label">
                Address
              </label>
              <div className="col-sm-8">
                <textarea
                  id="address"
                  name="address"
                  className="form-control form-control-sm mb-1"
                  rows="2"
                  maxLength="200"
                  placeholder="Address"
                  value={newData.address}
                  onChange={handleValueChange}
                ></textarea>
              </div>
              </div>

            
          </div>
          <div className="modal-footer">
            <SystemButton
              type={"close"}
              method={toggleFormModal}
              showText={true}
            />
            <SystemButton type={"save"} showText={true} />
          </div>
        </form>
      </FormModal>
      {/* End of form modal componenet */}
{/* 
      <ListView
        columns={dataColumns}
        rows={entities}
        edit={editRow}
        loadingState={isLoading}
        searchAndFetch={searchAndFetch}
        actionsColumn
        showEditButton
        showDeleteButton={false}
        resetSearch={resetSearch}
      /> */}
     
     
    </>
  );

  /* --- End of component renders --- */
};

export default Branches;
