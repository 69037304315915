import React, { useState, useEffect } from "react";
import { api, msg, cookie } from "../../../services";
import {
  DataTable,
  FormModal,
  ListView,
  SystemButton,
} from "../../../components";

const Vendors = () => {
  //Module name
  const moduleName = "Vendor";

  /* --- State declarationss --- */

  // Data states
  const [entities, setEntities] = useState([]);

  const [newData, setNewData] = useState({
    name: "",
    address: "",
    whatsapp: "",
    mobile: "",

  });

  // Data loading status
  const [isLoading, setIsLoading] = useState(false);

  // Form modal state
  const [showModalState, setShowModalState] = useState(false);

  // Data edit state
  const [isEdit, setIsEdit] = useState(false);

  // Selected data
  const [selectedId, setSelectedId] = useState("");


  const dataColumns = [
 
    { header: "Name", field: "name", showFilterMenu: false,searchable: true },
    { header: "Address", field: "address", showFilterMenu: false,searchable: true },
    { header: "Mobile", field: "mobile", showFilterMenu: false,searchable: true },
    { header: "Whatsapp", field: "whatsapp", showFilterMenu: false ,searchable: false}
  ];


  let dataRows = [];

  // List of districts
  const districtsList = [
    { code: "JAF", des: "Jaffna" },
    { code: "KIL", des: "Kilinochchi" },
    { code: "MAN", des: "Mannar" },
    { code: "MUL", des: "Mullaitivu" },
    { code: "VAV", des: "Vavuniya" },
    { code: "PUT", des: "Puttalam" },
    { code: "KUR", des: "Kurunegala" },
    { code: "GAM", des: "Gampaha" },
    { code: "COL", des: "Colombo" },
    { code: "KAL", des: "Kalutara" },
    { code: "MAT", des: "Matale" },
    { code: "KAN", des: "Kandy" },
    { code: "NUW", des: "Nuwara Eliya" },
    { code: "KEG", des: "Kegalle" },
    { code: "RAT", des: "Ratnapura" },
    { code: "TRI", des: "Trincomalee" },
    { code: "BAT", des: "Batticaloa" },
    { code: "AMP", des: "Ampara" },
    { code: "BAD", des: "Badulla" },
    { code: "MON", des: "Monaragala" },
    { code: "HAM", des: "Hambantota" },
    { code: "MAT", des: "Matara" },
    { code: "GAL", des: "Galle" },
  ];

  const cityList = [
    { code: "JAF", des: "Jaffna" },
    { code: "KIL", des: "Kilinochchi" },
    { code: "MAN", des: "Mannar" },
    { code: "MUL", des: "Mullaitivu" },
    { code: "VAV", des: "Vavuniya" },
    { code: "PUT", des: "Puttalam" },
    { code: "KUR", des: "Kurunegala" },
    { code: "GAM", des: "Gampaha" },
    { code: "COL", des: "Colombo" },
    { code: "KAL", des: "Kalutara" },
    { code: "MAT", des: "Matale" },
    { code: "KAN", des: "Kandy" },
    { code: "NUW", des: "Nuwara Eliya" },
    { code: "KEG", des: "Kegalle" },
    { code: "RAT", des: "Ratnapura" },
    { code: "TRI", des: "Trincomalee" },
    { code: "BAT", des: "Batticaloa" },
    { code: "AMP", des: "Ampara" },
    { code: "BAD", des: "Badulla" },
    { code: "MON", des: "Monaragala" },
    { code: "HAM", des: "Hambantota" },
    { code: "MAT", des: "Matara" },
    { code: "GAL", des: "Galle" },
  ];

  /* --- End of state declarations --- */

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (showModalState === false) {
      resetForm();
    }
  }, [showModalState]);

  /* --- Component functions --- */

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const response = await api.get("vendor");
      dataRows = [];

      if (response.error) {
        Object.values(response.error).forEach((err) => {
          msg.error(err[0]);
        });
        return;
      }
      response.data.map((entity) => {
        return dataRows.push({
          id: entity.id,
          address: entity.address ? entity.address : "",
          name: entity.name ? entity.name : "",
          mobile: entity.mobile ? entity.mobile : "",
          whatsapp: entity.whatsapp ? entity.whatsapp : "",

        });
      });

      setEntities(dataRows);

      setIsLoading(false);
    } catch (error) {
      msg.error("Unable to fetch data!");
    }
  };



  const toggleFormModal = () => {
    setShowModalState(!showModalState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await submitData();
  };

  const editRow = async (dataObj) => {
    console.log(dataObj);
    setShowModalState(true);
    setNewData({
      ...newData,
      name: dataObj.name,
      address: dataObj.address,
      whatsapp: dataObj.whatsapp,
      mobile: dataObj.mobile
    });

    setIsEdit(true);
    setSelectedId(dataObj.id);
  };

  const deleteRow = async (dataObj) => {
    try {
      const response = await api.delete(`vendor/${dataObj.id}`);

      if (response.status == 200 && response.data.status == 200) {
        msg.success(response.data.message);
        resetForm();
        fetchData();
        setShowModalState(false);
      } else if (response.status == 200 && response.data.status == 500) {
        msg.error(response.data.message);
      } else if (response.status == 200 && response.data.status == 400) {
        Object.values(response.data.message).forEach((err) => {
          msg.error(err[0]);
        });
      } else if (response.data.status == 400) {
        Object.values(response.message).forEach((err) => {
          msg.error(err[0]);
        });
      } else if (response.data.status == 520) {
        msg.warning(response.data.message);
      }
     
    } catch (error) {
    } finally {
      fetchData();
    }
  };

  const handleValueChange = (e) => {
    const targetInput = e.target;
    const inputName = targetInput.name;
    const inputValue =
      targetInput.type === "checkbox" ? targetInput.checked : targetInput.value;

    setNewData({
      ...newData,
      [inputName]: inputValue,
    });
  };

  const submitData = async () => {
    if (isEdit === false) {
      try {
        const response = await api.post("vendor").values(newData);
        if (response.status == 200 && response.data.status == 200) {
          msg.success(response.data.message);
          resetForm();
          fetchData();
          setShowModalState(false);
        } else if (response.status == 200 && response.data.status == 500) {
          msg.error(response.data.message);
        } else if (response.status == 200 && response.data.status == 400) {
          Object.values(response.data.message).forEach((err) => {
            msg.error(err[0]);
          });
        } else if (response.data.status == 400) {
          Object.values(response.message).forEach((err) => {
            msg.error(err[0]);
          });
        } else if (response.data.status == 520) {
          msg.warning(response.data.message);
        }
      } catch (error) {
        msg.error(error);
        return console.error(error);
      } 
    } else {
      try {
        const response = await api
          .update(`vendor/${selectedId}/update`)
          .values(newData);

          if (response.status == 200 && response.data.status == 200) {
            msg.success(response.data.message);
            resetForm();
            setIsEdit(false);
            setSelectedId("");
            fetchData();
            setShowModalState(false);
          } else if (response.status == 200 && response.data.status == 500) {
            msg.error(response.data.message);
          } else if (response.status == 200 && response.data.status == 400) {
            Object.values(response.data.message).forEach((err) => {
              msg.error(err[0]);
            });
          } else if (response.data.status == 400) {
            Object.values(response.message).forEach((err) => {
              msg.error(err[0]);
            });
          } else if (response.data.status == 520) {
            msg.warning(response.data.message);
          }
        } catch (error) {
          //  return console.log(error);
        }

       
    }
  };

  const resetForm = () => {
    setNewData({
      name: "",
      mobile: "",
      address: "",
      whatsapp: "",

    });
    setIsEdit(false);
  };

  const resetSearch = () => {
    setEntities([]);

    fetchData();
  };

  /* --- End of component functions --- */

  /* --- Component renders --- */

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{moduleName}</h1>
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header border-0">
                <div className="col-md-12 d-block">
                  <SystemButton
                    type="add-new"
                    classes={'btn-sm btn  btn-success float-sm-right'}
                    method={toggleFormModal}
                    showText
                    btnText="Add Vendor"
                  />
                </div>
              </div>
              <div className="card-body">
              <DataTable
              columns={dataColumns}
      dataList={entities}
      edit={editRow}
      deletedata={deleteRow}
      />
              </div>
            </div>
          </div>
        </div>
      </div>

      <FormModal
        moduleName={moduleName}
        modalState={showModalState}
        toggleFormModal={toggleFormModal}
        width="60%"
      >
        <form onSubmit={handleSubmit}>
          <div className="modal-body">
            
            <div className="row">
              <label htmlFor="name" className="col-sm-3 col-form-label">
                Full Name
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  name="name"
                  id="name"
                  maxLength="30"
                  className="form-control form-control-sm"
                  value={newData.name}
                  onChange={handleValueChange}
                  required
                />
              </div>
            </div>
            <div className="row">
              
                <label htmlFor="mobile" className="col-sm-3 col-form-label">Mobile Number</label>
                <div className="col-sm-6 form-group">
                <input
                  type="text"
                  name="mobile"
                  id="mobile"
                   maxLength="10"
                  minLength="10"
                  className="form-control form-control-sm"
                  value={newData.mobile}
                  onChange={handleValueChange}
                  required
                />
              </div>
             
            </div>
           
            <div className="row">
             
                <label htmlFor="whatsapp" className="col-sm-3 col-form-label">Whatsapp Number</label>
                <div className="col-sm-6 form-group">
                <input
                  type="text"
                  name="whatsapp"
                  id="whatsapp"
                   maxLength="10"
                  minLength="10"
                  className="form-control form-control-sm"
                  value={newData.whatsapp}
                  onChange={handleValueChange}
                  
                />
              </div>
             
            </div>
            <div className="row">
              <label htmlFor="address" className="col-sm-3 col-form-label">
                Address
              </label>
              <div className="col-sm-8">
                <textarea
                  id="address"
                  name="address"
                  className="form-control form-control-sm mb-1"
                  rows="2"
                  maxLength="200"
                  placeholder="Address"
                  value={newData.address}
                  onChange={handleValueChange}
                ></textarea>
              </div>

            
            </div>


          </div>
          <div className="modal-footer">
            <SystemButton
              type={"close"}
              method={toggleFormModal}
              showText={true}
            />
            <SystemButton type={"save"} showText={true} />
          </div>
        </form>
      </FormModal>
    </>
  );

  /* --- End of component renders --- */
};

export default Vendors;
