import api from './api';
import CookieService from './cookie';

//Cookie expire time
// const expiresAt = 86400;

const options = {
  path: '/',
  // domain: '173.82.240.210',
  // expires: new Date(Date.now() + expiresAt),
};

class AuthService {
  async doUserLogin(credentials) {
    console.log(credentials); 
    try {
      const response = await api.post('login').values(credentials);
      return response.data;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async handleLoginSuccess(response) {
    const token = response
      .toString()
      .substring(parseInt(response.toString().indexOf('|')) + parseInt(1));

    Promise.all(CookieService.set('access_token', token, options)) 
      .then() 
      .catch((err) => {
        throw err;
      });
  }

  async saveUserDetails(userDet) {
    console.log(userDet['user_data']);
    CookieService.set('user_id', userDet.user_data[0].id, options);
    CookieService.set('user_email', userDet.user_data[0].email, options);
    CookieService.set('user_branch', userDet.user_data[0].branch_id, options);
    // CookieService.set('user_branch_name',userDet.user_data[0].branch.name,options,); 
    CookieService.set('user_name', userDet.user_data[0].name, options);
    // CookieService.set('user_roles',userDet.user_data[0].roles.map((role) => {
    //   return role.id;
    // }),options,);
    // CookieService.set('user_role_name',userDet.user_data[0].roles.map((role) => {
    //   return role.name;
    // }),options,);
    // CookieService.set('permissions', userDet.permissions);
  }

  async signOutUser() {
    await api.post('logout').values(CookieService.get('user_id'));

    this.clearLocalStorage().then(() => {
      window.location.reload();
    });
  }

  async clearLocalStorage() {
    return new Promise((resolve, reject) => {

      CookieService.remove('access_token', options);
      CookieService.remove('user_id', options);
      CookieService.remove('user_email', options);
      CookieService.remove('user_branch', options);
      CookieService.remove('user_branch_name', options);
      CookieService.remove('user_name', options);
      CookieService.remove('user_roles', options);
      CookieService.remove('user_role_name', options);
      CookieService.remove('permissions', options);
      localStorage.removeItem('user_branch_code');
      localStorage.removeItem('user_branch');
      localStorage.removeItem('user_branch_name');
      localStorage.removeItem('user_key');
      localStorage.removeItem('token');

      resolve();
    });
  }
}

export default new AuthService();
