import axios from 'axios';
import CookieService from './cookie';

console.log(CookieService.get('access_token'));

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: CookieService.get('access_token')
    ? `Bearer ${CookieService.get('access_token')}`
    : null,
};

/**
 ** Uncomment the correct path for the API.... Or create your own API and link it... IDC
 */
const defaultPath = `${process.env.REACT_APP_DEFAULT_PATH}/api`; // Localhost
// const defaultPath = 'http://127.0.0.1:8000/api'; // Localhost
// const defaultPath = 'http://173.82.240.210/palliyaguruge-jewellers-server/api'; // Shared Host

const imagePath = `${process.env.REACT_APP_DEFAULT_PATH}/storage/images/`;

const defApiPath = `${process.env.REACT_APP_DEFAULT_PATH}`;


// const imagePath = 'http://127.0.0.1:8000/storage/images/';

export default {
  async get(url) {
    try {
      console.log(defaultHeaders)
      const res = await axios.get(`${defaultPath}/${url}`, {
        headers: defaultHeaders,
      });
      return res;
    } catch (err) {
      return { error: err.response.data.errors };
    }
  },

  async getapi(url) {
    try {
      console.log(defaultHeaders)
      const res = await axios.get(`${defApiPath}/${url}`, {
        headers: defaultHeaders,
      });
      return res;
    } catch (err) {
      return { error: err.response.data.errors };
    }
  },

  // post(url) {
  //   return {
  //     values:  (data) => {
  //       return new Promise((resolve, reject)=>{
  //         const res =  axios.post(`${defaultPath}/${url}`, data, {
  //           headers: defaultHeaders,
  //         });
  //           res.then(response => {
  //             resolve(response.data)
  //           });
  //           res.catch(error => {
  //             reject(error.response.data)
  //           });
  //         });
  //     },
  //   };
  // },

  post(url) {
    return {
      values: async (data) => {
        try {
          const res = await axios.post(`${defaultPath}/${url}`, data, {
            headers: defaultHeaders,
          });
          return res;
        } catch (err) {
          return err;
        }
      },
    };
  },


  
  postf(url,data, isMultipart = false) {
    const headers = isMultipart
      ? { Accept: 'application/json', Authorization: defaultHeaders.Authorization }
      : defaultHeaders;

    return {
      values: async () => {
        try {
         
           const res = await axios.post(`${defaultPath}/${url}`, data, {
        headers: headers,
      });
          return res;
        } catch (err) {
          return { error: err.response.data.errors };
        }
      },
    };
  },

  update(url) {
    return {
      values: async (data) => {
        try {
          const res = await axios.post(`${defaultPath}/${url}`, data, {
            headers: defaultHeaders,
          });
          return res;
        } catch (err) {
          return { error: err.response.data.errors };
        }
      },
    };
  },

  put(url, id) {
    return {
      values: async (data) => {
        try {
          const res = await axios.put(`${defaultPath}/${url}/${id}`, data, {
            headers: defaultHeaders,
          });
          return res;
        } catch (err) { 
          return { error: err.response.data.errors };
        }
      },
    };
  },

  async delete(url) {
    try {
      const res = await axios.delete(`${defaultPath}/${url}`, {
        headers: defaultHeaders,
      });
      return res;
    } catch (err) {
      return { error: err.response.data.errors };
    }
  },

  getMainImagePath() {
    return imagePath;
  },
};
