import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { api, msg, cookie } from '../../../services';
import { DataTable, SystemButton, FormModal } from '../../../components';

const localizer = momentLocalizer(moment);

const Booking = () => {
  const moduleName = 'Booking Calendar';
  const [entities, setEntities] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [items, setItems] = useState([]);
  const [events, setEvents] = useState([]);
  const [showModalState, setShowModalState] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [newData, setNewData] = useState({
    customer_id: '',
    employee_id: '',
    date: '',
    item_id: '',
    note: ''
  });

  useEffect(() => {
    fetchData();
  }, []);

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await api.get('all-bookings');
      if (response.error) {
        Object.values(response.error).forEach((err) => {
          msg.error(err[0]);
        });
        return;
      }
      setItems(response.data.items);

      // Generate random colors for each item
      const itemColors = {};
      response.data.items.forEach(item => {
        itemColors[item.id] = getRandomColor();
      });

      let dataRows = response.data.bookings.map((booking) => ({
        id: booking.id,
        employee_id: booking.employee_id,
        item_id: booking.item_id,
        item_name: booking.item.item_name,
        branch_id: cookie.get("user_branch"),
        user_id: cookie.get("user_id"),
        customer_id: booking.customer_id,
        employee_name: booking.employee.name,
        date: booking.booking_date,
        note: booking.note,
        bookingDetails: {
          service: booking.item.item_name,
          employee: booking.employee.name,
          customer: booking.customer.full_name,
          time: booking.start_time,
        },
        title: `${booking.item.item_name} - ${booking.customer.full_name}`,
        start: moment(booking.booking_date).toDate(),
        end: moment(booking.booking_date).toDate(),
        color: itemColors[booking.item_id],
      }));

      setEntities(dataRows);
      setEvents(dataRows.map(event => ({
        ...event,
        resource: { color: event.color }
      })));
    } catch (error) {
      console.log(error);
      msg.error('Unable to fetch data!');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEventClick = (event) => {
    const bookingDetails = event.bookingDetails;
    if (bookingDetails) {
      let modalContent = `<p><strong>Service:</strong> ${bookingDetails.service}</p>`;
      modalContent += `<p><strong>Employee:</strong> ${bookingDetails.employee}</p>`;
      modalContent += `<p><strong>Customer:</strong> ${bookingDetails.customer}</p>`;
      modalContent += `<p><strong>Time:</strong> ${bookingDetails.time}</p>`;
      document.getElementById('eventDetails').innerHTML = modalContent;
      document.getElementById('eventModal').style.display = 'block';
    }
  };

  const handleSlotSelect = (slotInfo) => {
    setSelectedDate(slotInfo.start);
    setShowModalState(true);
  };

  const toggleFormModal = () => {
    setShowModalState(!showModalState);
  };

  const handleValueChange = (e) => {
    const { name, value } = e.target;
    setNewData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic
    toggleFormModal(); // Close the modal after submission
  };

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{moduleName}</h1>
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              {/* <div className="card-header border-0">
                <div className="col-md-12 d-block">
                  <SystemButton
                    type="add-new"
                    classes={'btn-sm btn btn-success float-sm-right'}
                    method={() => setShowModalState(true)}
                    showText
                    btnText="Create Booking"
                  />
                </div>
              </div> */}
              <div className="card-body">
                <Calendar
                  localizer={localizer}
                  events={events}
                  startAccessor="start"
                  endAccessor="end"
                  style={{ height: 500 }}
                  onSelectEvent={handleEventClick}
                  onSelectSlot={handleSlotSelect}
                  selectable
                  eventPropGetter={(event) => ({
                    style: {
                      backgroundColor: event.resource.color,
                    }
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <FormModal
        moduleName={moduleName}
        modalState={showModalState}
        toggleFormModal={toggleFormModal}
        width="50%"
      >
        <form onSubmit={handleSubmit} className="compactForm">
          <div className="modal-body">
            <div className="row">
              <div className="col">
                <label htmlFor="customer_id">Customer</label><small className="text-danger">*</small>
                <select
                  className="form-control select2"
                  name="customer_id"
                  id="customer_id"
                  required
                  value={newData.customer_id}
                  onChange={handleValueChange}
                >
                  <option value="">Select</option>
                  {customers.map((customer) => (
                    <option key={customer.id} value={customer.id}>{customer.full_name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <label htmlFor="employee_id">Employee</label><small className="text-danger">*</small>
                <select
                  className="form-control select2"
                  name="employee_id"
                  id="employee_id"
                  required
                  value={newData.employee_id}
                  onChange={handleValueChange}
                >
                  <option value="">Select</option>
                  {employees.map((employee) => (
                    <option key={employee.id} value={employee.id}>{`${employee.first_name} ${employee.last_name}`}</option>
                  ))}
                </select>
              </div>
              <div className="col">
                <label htmlFor="date">Date</label><small className="text-danger">*</small>
                <input
                  type="date"
                  name="date"
                  className="form-control form-control-sm"
                  id="date"
                  required
                  value={selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : ''}
                  onChange={handleValueChange}
                />
                <small className="text-danger err" id="name-err"></small>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <label htmlFor="item_id">Items</label><small className="text-danger">*</small>
                <select
                  className="form-control select2"
                  name="item_id"
                  id="item_id"
                  required
                  value={newData.item_id}
                  onChange={handleValueChange}
                >
                  <option value="">Select</option>
                  {items.map((service) => (
                    <option key={service.id} value={service.id}>{service.item_name}</option>
                  ))}
                </select>
              </div>
              <div className="col">
                <label htmlFor="note">Note</label><small className="text-danger">*</small>
                <textarea
                  className="form-control"
                  id="note"
                  name="note"
                  value={newData.note}
                  onChange={handleValueChange}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <SystemButton type={'close'} method={toggleFormModal} showText={true} />
            <SystemButton type="no-form-save" method={handleSubmit} showText={true} />
          </div>
        </form>
      </FormModal>

      {/* Event Details Modal */}
      <div className="modal" id="eventModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Event Details</h4>
              <button type="button" className="close" onClick={() => document.getElementById('eventModal').style.display = 'none'}>&times;</button>
            </div>
            <div className="modal-body" id="eventDetails">
              {/* Event details will be injected here */}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" onClick={() => document.getElementById('eventModal').style.display = 'none'}>Close</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Booking;
