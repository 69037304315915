import React, { useState, useEffect } from "react";
import { api, msg, cookie } from "../../../services";
import {
  DataTable,
  FormModal,
  ListView,
  SystemButton,
} from "../../../components";

const Items = () => {
  //Module name
  const moduleName = "Items";

  /* --- State declarationss --- */

  // Data states
  const [entities, setEntities] = useState([]);

  const [itemCategories, setItemCategories] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [brandList, setBrandList] = useState([]);

  const [newData, setNewData] = useState({
    branch_id: "",
    brand_id: "",
    item_code: "",
    item_name: "",
    buyer_id: "",
    price: "",
    item_serial_number: "",
    buy_date: "",
    invoice_number: "",
    product_serial_number: "",
    item_no: "",
    warranty_period: "",
    user_id: cookie.get('user_id'),
  });

  // Data loading status
  const [isLoading, setIsLoading] = useState(false);

  // Form modal state
  const [showModalState, setShowModalState] = useState(false);

  // Data edit state
  const [isEdit, setIsEdit] = useState(false);

  // Selected data
  const [selectedId, setSelectedId] = useState("");

  // List view states
  const dataColumns = [
    {
      header: "Branch",
      field: "branch_name",
      showFilterMenu: false,
      searchable: true,
    },
  
    {
      header: "Serial Number",
      field: "item_serial_number",
      showFilterMenu: false,
      searchable: true,
    },
    {
      header: "Item No",
      field: "item_no",
      showFilterMenu: false,
      searchable: false,
    },
    {
      header: "Item Code",
      field: "item_code",
      showFilterMenu: false,
      searchable: true,
    },
    {
      header: "Item Name",
      field: "item_name",
      showFilterMenu: false,
      searchable: true,
    },
    { header: "Rent Price", field: "rent_price", showFilterMenu: false, searchable: true },


  ];

  let dataRows = [];

  /* --- End of state declarations --- */
  useEffect(() => {
    fetchData();
    fetchBranches();
    fetchBrandes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showModalState === false) {
      resetForm();
    }
  }, [showModalState]);

  /* --- Component functions --- */

  const fetchData = async () => {
    dataRows = [];

    try {
      setIsLoading(true);
      const response = await api.get("items");

      console.log(response.data);

      if (response.error) {
        Object.values(response.error).forEach((err) => {
          msg.error(err[0]);
        });
        return;
      } else {
        response.data.map((entity) => {
          dataRows.push({
            id: entity.id,
            branch_id: entity.branch_id,
            branch_name: entity.branch.name,
            brand_id: entity.brand_id,
            item_code: entity.item_code,
            item_name: entity.item_name,
            buyer_id: entity.buyer_id,
            rent_price: entity.rent_price,
            item_serial_number: entity.item_serial_number,
            buy_date: entity.buy_date,
            invoice_number: entity.invoice_number,
            product_serial_number: entity.product_serial_number,
            item_no: entity.item_no,
            warranty_period: entity.warranty_period,
            user_id:entity.user_id
          });
        });
      }
      setIsLoading(false);
      setEntities(dataRows);
    } catch (error) {
      return console.log(error);
    }
  };

  const fetchBranches = async () => {
    try {
      const response = await api.get("all-branches");

      if (response.error) {
        Object.values(response.error).forEach((err) => {
          msg.error(err[0]);
        });
        return;
      } else {
        setBranchList(response.data);
      }
    } catch (error) {
      return console.log(error);
    }
  };

  const fetchBrandes = async () => {
    try {
      const response = await api.get("brand");

      if (response.error) {
        Object.values(response.error).forEach((err) => {
          msg.error(err[0]);
        });
        return;
      } else {
        setBrandList(response.data.entities);
      }
    } catch (error) {
      return console.log(error);
    }
  };

  const toggleFormModal = () => {
    setShowModalState(!showModalState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await submitData();
    fetchData();
  };

  const editRow = (dataObj) => {
    console.log(dataObj);
    setNewData({
      branch_id: dataObj.branch_id,
      brand_id: dataObj.brand_id,
      item_code: dataObj.item_code,
      item_name: dataObj.item_name,
      buyer_id: dataObj.buyer_id,
      rent_price: dataObj.rent_price,
      item_serial_number: dataObj.item_serial_number,
      buy_date: dataObj.buy_date,
      invoice_number: dataObj.invoice_number,
      product_serial_number: dataObj.product_serial_number,
      item_no: dataObj.item_no,
      warranty_period: dataObj.warranty_period,
      user_id:cookie.get('user_id')
    });

    setShowModalState(true);
    setIsEdit(true);
    setSelectedId(dataObj.id);
  };

  const deleteRow = async (dataObj) => {
    try {
      const response = await api.delete(`items/${dataObj.id}`);
      if (response.status == 200 && response.data.status == 200) {
        msg.success(response.data.message);
        fetchData();
      } else if (response.status == 200 && response.data.status == 500) {
        msg.error(response.data.message);
      } else if (response.status == 200 && response.data.status == 400) {
        Object.values(response.data.message).forEach((err) => {
          msg.error(err[0]);
        });
      } else if (response.data.status == 400) {
        Object.values(response.message).forEach((err) => {
          msg.error(err[0]);
        });
      } else if (response.data.status == 520) {
        msg.warning(response.data.message);
      }
    } catch (error) {
      return console.log(error);
    } 
  };

  const handleValueChange = (e) => {
    const targetInput = e.target;
    const inputName = targetInput.name;
    let inputValue = targetInput.value;

    if (inputName === "date") {
      const formattedDate = formatDateString(inputValue);
      setNewData({ ...newData, [inputName]: formattedDate });
    } else {
      setNewData({ ...newData, [inputName]: inputValue });
    }

    // if (inputName === "branch_id" || inputName === "brand_id") {
    //   setNewData({ ...newData, [inputName]: inputValue });
    //   const itemCode = generateItemCode(inputValue);
    //   setNewData((prevData) => ({
    //     ...prevData,
    //     branch_id: inputValue,
    //     item_code: itemCode,
    //   }));
    // }

    // if (inputName === 'brand_id') {
    //   const itemCode = generateItemCode(inputValue);
    //   setNewData((prevData) => ({ ...prevData, branch_id: inputValue, item_code: itemCode }));
    //   }

    // setNewData({
    //   ...newData,
    //   [inputName]: inputValue,
    // });
  };

  const generateItemCode = (branchCode) => {
    const date = new Date();
    const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Get month and pad with zero if needed
    const day = ("0" + date.getDate()).slice(-2); // Get day and pad with zero if needed
    const uniqueIdentifier = Math.floor(1000 + Math.random() * 9000); // Generate a random 4-digit number

    return `${branchCode}-${year}${month}${day}-${uniqueIdentifier}`;
  };

  const submitData = async () => {
    if (isEdit === false) {
      try {
        const response = await api.post("items").values(newData);
        if (response.status == 200 && response.data.status == 200) {
          msg.success(response.data.message);
          resetForm();
          fetchData();
          setShowModalState(false);
        } else if (response.status == 200 && response.data.status == 500) {
          msg.error(response.data.message);
        } else if (response.status == 200 && response.data.status == 400) {
          Object.values(response.data.message).forEach((err) => {
            msg.error(err[0]);
          });
        } else if (response.data.status == 400) {
          Object.values(response.message).forEach((err) => {
            msg.error(err[0]);
          });
        } else if (response.data.status == 520) {
          msg.warning(response.data.message);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        const response = await api
          .update(`items/${selectedId}/update`)
          .values(newData);
          if (response.status == 200 && response.data.status == 200) {
            msg.success(response.data.message);
            resetForm();
            fetchData();
            setShowModalState(false);
          } else if (response.status == 200 && response.data.status == 500) {
            msg.error(response.data.message);
          } else if (response.status == 200 && response.data.status == 400) {
            Object.values(response.data.message).forEach((err) => {
              msg.error(err[0]);
            });
          } else if (response.data.status == 400) {
            Object.values(response.message).forEach((err) => {
              msg.error(err[0]);
            });
          } else if (response.data.status == 520) {
            msg.warning(response.data.message);
          }
      } catch (error) {
        console.error(error);
      } 
    }
  };

  const resetForm = () => {
    setNewData({
      branch_id: '',
      brand_id: '',
      item_code: '',
      item_name: '',
      buyer_id: '',
      rent_price: '',
      item_serial_number: '',
      buy_date: '',
      invoice_number:'',
      product_serial_number: '',
      item_no: '',
      warranty_period: '',
      user_id: cookie.get("user_id"),
    });
    setIsEdit(false);
    setSelectedId('');
  };

  const formatDateString = (value) => {
    // Remove non-numeric characters
    const numericValue = value.replace(/[^0-9]/g, "");

    // Format the date string as YYYY-MM-DD
    let formattedDate = numericValue;

    if (numericValue.length > 4) {
      formattedDate = `${numericValue.slice(0, 4)}-${numericValue.slice(4, 6)}`;
    }

    if (numericValue.length > 6) {
      formattedDate = `${formattedDate}-${numericValue.slice(6, 8)}`;
    }

    return formattedDate;
  };



  /* --- End of component functions --- */

  /* --- Component renders --- */

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{moduleName}</h1>
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header border-0">
                <div className="col-md-12 d-block">
                  <SystemButton
                    type="add-new"
                    classes={'btn-sm btn  btn-success float-sm-right'}
                    method={toggleFormModal}
                    showText
                    btnText="Add Item"
                  />
                </div>
              </div>
              <div className="card-body">
                <DataTable
                  columns={dataColumns}
                  dataList={entities}
                  edit={editRow}
                  deletedata={deleteRow}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal componenet */}
      <FormModal
        moduleName={moduleName}
        modalState={showModalState}
        width="60%"
        toggleFormModal={toggleFormModal}
      >
        <form onSubmit={handleSubmit} className="compactForm">
          <div className="modal-body">
            <div className="row">
                <label htmlFor="branch_id"  className="col-sm-3 col-form-label"> Branch</label>
                <div className="col-sm-8 form-group">
                <select
                  name="branch_id"
                  id="branch_id"
                  className="form-control form-control-sm"
                  value={newData.branch_id}
                  onChange={handleValueChange}
                >
                  <option value="">-- Select Branch </option>
                  {branchList.map((category, index) => {
                    return (
                      <option key={index} value={category.id}>
                        {category.name}
                      </option>
                    );
                  })}
                </select>
              </div>
          
            </div>
            <div className="row">
              <label
                htmlFor="item_serial_number"
                className="col-sm-3 col-form-label"
              >
                Serial Number
              </label>
              <div className="col-sm-8 form-group">
                <input
                  type="text"
                  name="item_serial_number"
                  id="item_serial_number"
                  placeholder="Enter item name"
                  className="form-control"
                  maxLength="50"
                  value={newData.item_serial_number}
                  onChange={handleValueChange}
                />
              </div>
            </div>

            <div className="row">
              <label htmlFor="item_no" className="col-sm-3 col-form-label">
                Item No
              </label>
              <div className="col-sm-8 form-group">
                <input
                  type="text"
                  name="item_no"
                  id="item_no"
                  placeholder="Enter item No"
                  className="form-control"
                  // maxLength="5"
                  value={newData.item_no}
                  onChange={handleValueChange}
                />
              </div>
            </div>
            <div className="row">
              <label htmlFor="item_code" className="col-sm-3 col-form-label">
                Item Code
              </label>
              <div className="col-sm-8 form-group">
                <input
                  type="text"
                  name="item_code"
                  id="item_code"
                  placeholder="Enter Item Code"
                  className="form-control"
                  value={newData.item_code}
                  onChange={handleValueChange}
                  
                />
              </div>
            </div>
            <div className="row">
              <label htmlFor="item_name" className="col-sm-3 col-form-label">
                Item Name
              </label>
              <div className="col-sm-8 form-group">
                <input
                  type="text"
                  name="item_name"
                  id="item_name"
                  placeholder="Enter item No"
                  className="form-control"
                  // maxLength="5"
                  value={newData.item_name}
                  onChange={handleValueChange}
                />
              </div>
            </div>
            <div className="row">
              <label htmlFor="price" className="col-sm-3 col-form-label">
                Item Rent Price
              </label>
              <div className="col-sm-8 form-group">
                <input
                  type="text"
                  name="rent_price"
                  id="rent_price"
                  placeholder="Enter item No"
                  className="form-control"
                  // maxLength="5"
                  value={newData.rent_price}
                  onChange={handleValueChange}
                />
              </div>
            </div>
        
          </div>
          <div className="modal-footer">
            <SystemButton
              type={"close"}
              method={toggleFormModal}
              showText={true}
            />
            <SystemButton type={"save"} showText={true} />
          </div>
        </form>
      </FormModal>

    </>
  );

  /* --- End of component renders --- */
};

export default Items;
