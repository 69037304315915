import React, { useState, useEffect } from 'react';
import { api, msg, cookie } from '../../../services';
import { DataTable, FormModal, ListView, SystemButton } from '../../../components';
import moment from 'moment';

const Brand = () => {
  // Module name
  const moduleName = 'Saloon';

  /* --- State declarationss --- */

  const [entities, setEntities] = useState([]);

  const [newData, setNewData] = useState({
    name: '',
    address: '',
    mobile: '',
    city: '',
    district: '',
    fax: '',
    email: '',
    branch_id: 1,
    status: 1,
    
  });

  // Form modal state
  const [showModalState, setShowModalState] = useState(false);

  // Data edit state
  const [isEdit, setIsEdit] = useState(false);

  // Data loading status
  const [isLoading, setIsLoading] = useState(false);

  // Selected data
  const [selectedId, setSelectedId] = useState('');

  // List view states
  const dataColumns = [
    {
      header: "Name",
      field: "name",
      showFilterMenu: false,
      searchable: true
    },
    { header: "Mobile", field: "mobile", showFilterMenu: false,searchable: true },
  ];
  let dataRows = [];


  const cityList = [
    { code: "JAF", des: "Jaffna" },
    { code: "KIL", des: "Kilinochchi" },
    { code: "MAN", des: "Mannar" },
    { code: "MUL", des: "Mullaitivu" },
    { code: "VAV", des: "Vavuniya" },
    { code: "PUT", des: "Puttalam" },
    { code: "KUR", des: "Kurunegala" },
    { code: "GAM", des: "Gampaha" },
    { code: "COL", des: "Colombo" },
    { code: "KAL", des: "Kalutara" },
    { code: "MAT", des: "Matale" },
    { code: "KAN", des: "Kandy" },
    { code: "NUW", des: "Nuwara Eliya" },
    { code: "KEG", des: "Kegalle" },
    { code: "RAT", des: "Ratnapura" },
    { code: "TRI", des: "Trincomalee" },
    { code: "BAT", des: "Batticaloa" },
    { code: "AMP", des: "Ampara" },
    { code: "BAD", des: "Badulla" },
    { code: "MON", des: "Monaragala" },
    { code: "HAM", des: "Hambantota" },
    { code: "MAT", des: "Matara" },
    { code: "GAL", des: "Galle" },
  ];

  /* --- End of state declarations --- */

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // if (showModalState === false) {
    //   resetForm();
    // }
  }, [showModalState]);

  /* --- Component functions --- */

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const response = await api.get('saloon');

      console.log(response.data.entities);

      if (response.error) {
        Object.values(response.error).forEach((err) => {
          msg.error(err[0]);
        });
        return;
      }

      response.data.map((entity) => {
        dataRows.push({
          id: entity.id,
          mobile: entity.mobile,
          name: entity.name,
          address: entity.address,
          city: entity.city,
          district: entity.district,
          email: entity.email,
        });
      });

      setEntities(dataRows);
    } catch (error) {
      console.log(error);
      msg.error('Unable to fetch data!');
    } finally {
      setIsLoading(false);
    }
  };



  const handleValueChange = (e) => {
    const targetInput = e.target;
    const inputName = targetInput.name;
    // const inputValue = targetInput.value;
    const inputValue =
      targetInput.type === 'checkbox' ? targetInput.checked : targetInput.value;

    setNewData({
      ...newData,
      [inputName]: inputValue,
    });
  };

  const editRow = (dataObj) => {
    console.log(dataObj);
    setShowModalState(true);
    setNewData({
      mobile: dataObj.mobile,
      name: dataObj.name,
      city: dataObj.city,
      district: dataObj.district,
      email:dataObj.email
    });

    setIsEdit(true);
    setSelectedId(dataObj.id);
  };

  const toggleFormModal = () => {
    setShowModalState(!showModalState);
  };

  const handleSubmit = async () => {
    await save();

  //  resetForm();
// fetchData();
  };

  const save = async () => {
    if (isEdit === false) {
      try {
        const response = await api.post('saloon').values(newData);
        if (response.status == 200 && response.data.status == 200) {
          msg.success(response.data.message);
          resetForm();
          setShowModalState(false);
          fetchData();
        } else if (response.status == 200 && response.data.status == 500) {
          msg.error(response.data.message);
        } else if (response.status == 200 && response.data.status == 400) {
          Object.values(response.data.message).forEach((err) => {
            msg.error(err[0]);
          });
        } else if (response.data.status == 400) {
          Object.values(response.message).forEach((err) => {
            msg.error(err[0]);
          });
        }
      } catch (error) {
        msg.error(error);
        return console.log(error);
      } 
      // finally {
      //   setShowModalState(false);
      // }
    } else {
      try {
        const response = await api
          .update(`saloon/${selectedId}/update`)
          .values(newData);
          if (response.status == 200 && response.data.status == 200) {
            msg.success(response.data.message);
            resetForm();
            setShowModalState(false);
            fetchData();
          } else if (response.status == 200 && response.data.status == 500) {
            msg.error(response.data.message);
          } else if (response.status == 200 && response.data.status == 400) {
            Object.values(response.data.message).forEach((err) => {
              msg.error(err[0]);
            });
          } else if (response.data.status == 400) {
            Object.values(response.message).forEach((err) => {
              msg.error(err[0]);
            });
          }
      } catch (error) {
        msg.error(error);
        return console.error(error);
      } 
      // finally {
      //   setIsEdit(false);
      //   setSelectedId('');
      //   setShowModalState(false);
      // }
    }
  };
  const deleteRow = async (dataObj) => {
    try {
      const response = await api.delete(`saloon/${dataObj.id}`);
      if (response.status == 200 && response.data.status == 200) {
        msg.success(response.data.message);
        fetchData();
      } else if (response.status == 200 && response.data.status == 500) {
        msg.error(response.data.message);
      } else if (response.status == 200 && response.data.status == 400) {
        Object.values(response.data.message).forEach((err) => {
          msg.error(err[0]);
        });
      } else if (response.data.status == 400) {
        Object.values(response.message).forEach((err) => {
          msg.error(err[0]);
        });
      } else if (response.data.status == 520) {
        msg.warning(response.data.message);
      }
    } catch (error) {
    } 
  };

  const resetForm = () => {
    setNewData({
      ...newData,
      name: '',
      address: '',
      mobile: '',
      city: '',
      district: '',
      fax: '',
      email: '',
      branch_id: 1,
      status: 1,
    });

    setIsEdit(false);
  };

  const resetSearch = () => {
    setEntities([]);

    fetchData();
  };

  /* --- End of component functions --- */

  /* --- Component renders --- */

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{moduleName}</h1>
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header border-0">
                <div className="col-md-12 d-block">
                  <SystemButton
                    type="add-new"
                    classes={'btn-sm btn  btn-success float-sm-right'}
                    method={toggleFormModal}
                    showText
                    btnText="Add Saloon"
                  />
                </div>
              </div>
              <div className="card-body">
              <DataTable
              columns={dataColumns}
      dataList={entities}
      edit={editRow}
      deletedata={deleteRow}
      />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* End of form modal componenet */}
      <FormModal
        moduleName={moduleName}
        modalState={showModalState}
        toggleFormModal={toggleFormModal}
        width="50%"
      >
        {/* <form onSubmit={handleSubmit} className="compactForm">
        </form> */}
        <div>
        <div className="modal-body">
           
            <div className="row">
              <label htmlFor="name" className="col-sm-3 col-form-label">
                 Name
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  name="name"
                  id="name"
                  maxLength="30"
                  className="form-control form-control-sm"
                  value={newData.name}
                  onChange={handleValueChange}
                  required
                />
              </div>
            </div>
            <div className="row">
              
                <label htmlFor="mobile" className="col-sm-3 col-form-label">Mobile Number</label>
                <div className="col-sm-6 form-group">
                <input
                  type="text"
                  name="mobile"
                  id="mobile"
                   maxLength="10"
                  minLength="10"
                  className="form-control form-control-sm"
                  value={newData.mobile}
                  onChange={handleValueChange}
                  required
                />
              </div>
             
            </div>
            <div className="row">
              
                <label htmlFor="city" className="col-sm-3 col-form-label"> City</label>
                <div className="col-sm-6 form-group">
                <select
                  name="city"
                  id="city"
                  className="form-control form-control-sm"
                  value={newData.city}
                  onChange={handleValueChange}
                >
                  <option value="">-- Select City</option>
                  {cityList.map((category, index) => {
                    return (
                      <option key={index} value={category.code}>
                        {category.des}
                      </option>
                    );
                  })}
                </select>
              </div>
              {/* <div className="col-sm-6 form-group">
                <label htmlFor="district"> District</label>
                <select
                  name="district"
                  id="district"
                  className="form-control form-control-sm"
                  value={newData.district}
                  onChange={handleValueChange}
                >
                  <option value="">-- Select District --</option>
                  {districtsList.map((district, index) => (
                    <option key={index} value={district.code}>
                      {district.des}
                    </option>
                  ))}
                </select>
              </div> */}
            </div>
            

            <div className="row">
              <label htmlFor="email" className="col-sm-3 col-form-label">
                Email
              </label>
              <div className="col-sm-6">
                <input
                  type="text"
                  name="email"
                  id="email"
                  maxLength="20"
                  minLength="9"
                  className="form-control form-control-sm"
                  value={newData.email}
                  onChange={handleValueChange}
                />
              </div>
            </div>

           
           
            
          </div>

          <div className="modal-footer">
            <SystemButton
              type={'close'}
              method={toggleFormModal}
              showText={true}
            />
            <SystemButton
              type="no-form-save"
              method={handleSubmit}
              showText={true}
            />
          </div>
        </div>
      </FormModal>
     

      {/* List view componenet */}
      {/* <ListView
        columns={dataColumns}
        rows={entities}
        edit={editRow}
        loadingState={isLoading}
        searchAndFetch={searchAndFetch}
        actionsColumn
        showEditButton
        resetSearch={resetSearch}
        rowKey="code"
      /> */}
      {/* End of list view component */}
    </>
  );

  /* --- End of component renders --- */
};

export default Brand;
