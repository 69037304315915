import React, { Component } from "react";
import { auth } from "../services";
import { Find, NavMsg, Pos } from "../views";
import { faBinoculars, faCalendar, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, NavLink } from "react-router-dom/cjs/react-router-dom.min";
const signOut = async () => {
  auth.signOutUser();
};
const Header = () => {
  return (
    <div>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light bg-gradient-info text-bold">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link " data-widget="pushmenu" href="#" role="button">
              <i class="fas fa-bars"></i>
            </a>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <NavLink to="/pos" target='_blank' className="nav-link">
              <FontAwesomeIcon icon={faUserAlt} color="white" />
            </NavLink>
          </li>
          

          <li class="nav-item d-none d-sm-inline-block">
            <a href="/" class="nav-link">
              Reports
            </a>
          </li>
          <li class="nav-item d-none d-sm-inline-block">
          <NavLink to="/calender" className="nav-link">
              <FontAwesomeIcon icon={faCalendar} color="white" />
            </NavLink>
          </li>
        </ul>

        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link" data-widget="fullscreen" href="#" role="button">
              <i class="fas fa-expand-arrows-alt"></i>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" 
             onClick={() => signOut()}
            role="button">
              <i class="fas fa-sign-out-alt"></i>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Header;
