import React from 'react';
import styles from './FormModal.module.css';

const FormModal = (props) => {
  const modalWidth = props.width ? `${props.width}` : '90%';
  const modalHeight = props.height ? `${props.height}` : 'fit-content';

  return props.modalState === false ? null : (
    <div
      className={styles.modal}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="formModalTitle"
      style={{ paddingTop:'50px'}}
    >
      <div
        className={styles.modalContent}
        style={{ width: modalWidth, height: modalHeight }}
      >
        <div className={styles.modalHeader}>
          <span
            className={styles.close}
            onClick={() => props.toggleFormModal()}
          >
            &times;
          </span>
          <h5 className="h4" id="formModalTitle">
            {props.moduleName}
          </h5>
        </div>
        {props.children}
      </div>
    </div>
  );
};

export default FormModal;
