import React, { useState, useEffect } from 'react';
import { api, msg } from '../services';
import { DataTable, FormModal, ListView, SystemButton } from '../components';
import moment from 'moment';

const Users = () => {
  // Module name
  const moduleName = 'Users';

  /* --- State declarationss --- */

  const [entities, setEntities] = useState([]);

  const [newData, setNewData] = useState({
    id: '',
    name: '',
    email: '',
    password: '',
    branch_id: '',
  });

  const [branches, setBranches] = useState([]);

  // Form modal state
  const [showModalState, setShowModalState] = useState(false);

  // Data loading status
  const [isLoading, setIsLoading] = useState(false);

  // Data edit state
  const [isEdit, setIsEdit] = useState(false);
  const [branchList, setBranchList] = useState([]);

  // Selected data
  const [selectedId, setSelectedId] = useState('');

  // List view states
  const dataColumns = [
    { header: 'Id', field: 'id', searchable: true },
    { header: 'Name', field: 'name' ,searchable: true },
    { header: 'Email', field: 'email' ,searchable: true },
    { header: 'Branch', field: 'branch_name',searchable: true },
  ];

  let dataRows = [];

  let is_passwords_match = true;

  /* --- End of state declarations --- */

  useEffect(() => {
    fetchData();  
      fetchBranches();
  }, []);

  useEffect(() => {
    if (showModalState === false) {
      resetForm();
    }
  }, [showModalState]);

  /* --- Component functions --- */

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const response = await api.get('users');
      response.data.map(user => {
        dataRows.push(
          {
            id: user.id,
            name: user.name,
            email: user.email,
            branch_id: user.branch_id,
            branch_name: user.branch.name,
          }
        )
      })

      setEntities(dataRows);

      if (response.error) {
        Object.values(response.error).forEach((err) => {
          msg.error(err[0]);
        });
        return;
      }
    } catch (error) {
      msg.error('Unable to fetch data!');
      return console.log(error);
    } finally {
      setIsLoading(false);
    }
  };


  const fetchBranches = async () => {
    try {
      const response = await api.get("all-branches");

      if (response.error) {
        Object.values(response.error).forEach((err) => {
          msg.error(err[0]);
        });
        return;
      } else {
        setBranchList(response.data);
      }
    } catch (error) {
      return console.log(error);
    }
  };


  const handleValueChange = (e) => {
    const targetInput = e.target;
    const inputName = targetInput.name;
    const inputValue = targetInput.value;

    if (inputName === 'conf_password') {
      if (inputValue === newData.password) {
        is_passwords_match = true;
      } else {
        is_passwords_match = false;
      }
    } else {
      setNewData({
        ...newData,
        [inputName]: inputValue,
      });
    }
  };

  const toggleFormModal = () => {
    setShowModalState(!showModalState);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    await save();
    // resetAll();
    // fetchData();
  };

  const save = async () => {
    if (is_passwords_match) {
      try {
        if (!isEdit) {
          const response = await api.post('register').values(newData);

          if (response.status == 200 && response.data.status == 200) {
            msg.success(response.data.message);
            resetForm();
            fetchData();
            setShowModalState(false);
          } else if (response.status == 200 && response.data.status == 400) {
            Object.values(response.data.message).forEach((err) => {
              msg.error(err[0]);
            });
          } else if (response.status == 200 && response.data.status == 500) {
            msg.error(response.data.message);
            // if (response.data.success == false) {
            //   Object.values(response.data.error).forEach((err) => {
            //     msg.error(err[0]);
            //   });
            // } else {
            //   msg.success('User Created');
            //   resetAll();
            //   fetchData();
            //   setShowModalState(false);
            // }
          } else {
            msg.error('Error creating user');
          }
        } else {
          //console.log(newData);
          const response = await api
            .put('editUser', newData.id)
            .values(newData);

          if (response.status == 200 && response.data.status == 200) {
            msg.success(response.data.message);
            resetAll();
            fetchData();
            setShowModalState(false);
          } else if (response.status == 200 && response.data.status == 500) {
            msg.error(response.data.message);
          } else if (response.status == 200 && response.data.status == 400) {
            Object.values(response.data.message).forEach((err) => {
              msg.error(err[0]);
            });
          } else if (response.data.status == 400) {
            Object.values(response.message).forEach((err) => {
              msg.error(err[0]);
            });
          }

          // msg.success(response.data);
          // resetAll();
          // fetchData();
          // setShowModalState(false);
        }
      } catch (error) {
        msg.error(error);
        //   return console.log(error);
      } finally {
        // setShowModalState(false);
      }
    } else {
      msg.error(`Password and Confirmation Password fields doesn't match`);
    }
  };

  const resetAll = () => {
    setEntities([]);

    setNewData({
      id: '',
      name: '',
      email: '',
      password: '',
      branch_id: '',
    });

    setShowModalState(false);

    setIsLoading(false);

    dataRows = [];
  };

  const editRow = (dataObj) => {
    setNewData({
      id: dataObj.id,
      name: dataObj.name,
      email: dataObj.email,
      branch_id: dataObj.branch_id,
    });

    setShowModalState(true);
    setIsEdit(true);
    setSelectedId(dataObj.id);
  };

  const deleteRow = async (dataObj) => {
    try {
      const response = await api.delete(`users/${dataObj.id}`);
      if (response.status == 200 && response.data.status == 200) {
        msg.success(response.data.message);
        fetchData();
      } else if (response.status == 200 && response.data.status == 500) {
        msg.error(response.data.message);
      } else if (response.status == 200 && response.data.status == 400) {
        Object.values(response.data.message).forEach((err) => {
          msg.error(err[0]);
        });
      } else if (response.data.status == 400) {
        Object.values(response.message).forEach((err) => {
          msg.error(err[0]);
        });
      } else if (response.data.status == 520) {
        msg.warning(response.data.message);
      }
    } catch (error) {
      msg.error(error.message);
    }
  };

  const resetForm = () => {
    setNewData({
      id: '',
      name: '',
      email: '',
      branch_id: '',
    });
    setIsEdit(false);
  };
  /* --- End of component functions --- */

  /* --- Component renders --- */

  return (
    <>
     <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{moduleName}</h1>
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header border-0">
                <div className="col-md-12 d-block">
                  <SystemButton
                    type="add-new"
                    classes={"btn-sm btn  btn-success float-sm-right"}
                    method={toggleFormModal}
                    showText
                    btnText="Add User"
                  />
                </div>
              </div>
              <div className="card-body">
                <DataTable
                  columns={dataColumns}
                  dataList={entities}
                  edit={editRow}
                  deletedata={deleteRow}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Form modal componenet */}
      <FormModal
        moduleName={moduleName}
        modalState={showModalState}
        toggleFormModal={toggleFormModal}
          width="60%"
      >
        <form onSubmit={handleSubmit} className="compactForm">
          <div className="modal-body">
            <div className="row">
              <div className="col-sm-6">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="form-control form-control-sm"
                  value={newData.name}
                  onChange={handleValueChange}
                />
              </div>
              <div className="col-sm-6">
                <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control form-control-sm"
                    value={newData.email}
                    onChange={handleValueChange}
                    
                  />
                
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="form-control form-control-sm"
                  value={newData.password}
                  onChange={handleValueChange}
                />
              </div>
              <div className="col-sm-6">
                <label htmlFor="conf_password">Confirm Password</label>
                <input
                  type="password"
                  name="conf_password"
                  id="conf_password"
                  className="form-control form-control-sm"
                  value={newData.conf_password}
                  onChange={handleValueChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <label htmlFor="branch_id">Branch</label>
                <select
                  name="branch_id"
                  id="branch_id"
                  className="form-control form-control-sm"
                  value={newData.branch_id}
                  onChange={handleValueChange}
                >
                  <option value="">-- Select Branch </option>
                  {branchList.map((category, index) => {
                    return (
                      <option key={index} value={category.id}>
                        {category.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <SystemButton
              type={'close'}
              method={toggleFormModal}
              showText={true}
            />
            <SystemButton type={'save'} showText={true} />
          </div>
        </form>
      </FormModal>
      {/* End of form modal componenet */}

    
    </>
  );

  /* --- End of component renders --- */
};

export default Users;
