import React, { useEffect, useState } from 'react';
import { HashRouter as Router, useLocation } from 'react-router-dom';
import { NavBar, SideNav, ComponentContainer, Header, Menu } from './components';
import { Login } from './auth';
import { routes, cookie, api, auth } from './services';
import './App.css';
import PosHeader from './components/PosHeader';

const loadCSS = (path) => {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = path;
  document.head.appendChild(link);
};

const removeCSS = (path) => {
  const links = document.querySelectorAll(`link[href='${path}']`);
  links.forEach(link => link.remove());
};

const loadScript = (src, onLoad) => {
  const script = document.createElement('script');
  script.src = src;
  script.async = true;
  if (onLoad) script.onload = onLoad;
  document.body.appendChild(script);
  return script;
};

const removeScript = (src) => {
  const scripts = document.querySelectorAll(`script[src='${src}']`);
  scripts.forEach(script => script.remove());
};

const selectCSS = (pathname) => {
  const commonCSS = [
    // '/plugins/fontawesome-free/css/all.min.css',
    // 'https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css',
    // '/plugins/datatables-bs4/css/dataTables.bootstrap4.min.css',
    // '/plugins/datatables-responsive/css/responsive.bootstrap4.min.css'
  ];

  const posCSS = [
    // '/vendor/bootstrap/css/bootstrap.min.css',
    // '/vendor/bootstrap-toggle/css/bootstrap-toggle.min.css',
    // '/vendor/bootstrap/css/bootstrap-select.min.css',
    // '/vendor/font-awesome/css/font-awesome.min.css',
    // 'https://fonts.googleapis.com/css?family=Nunito:400,500,700',
    // '/vendor/malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css',
    // '/vendor/keyboard/css/keyboard.css',
    // '/css/style.default.css',
    // '/css/style.css',
    // '/css/custom-default.css',
    // '/plugins/icon-kit/dist/css/iconkit.min.css',
    // '/plugins/ionicons/dist/css/ionicons.min.css',
    // 'https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css',
    // '/vendor/bootstrap-toggle/css/bootstrap-toggle.min.css',
    // '/vendor/bootstrap/css/bootstrap-datepicker.min.css',
    // '/vendor/jquery-timepicker/jquery.timepicker.min.css',
    // '/vendor/bootstrap/css/awesome-bootstrap-checkbox.css',
    // '/vendor/bootstrap/css/bootstrap-select.min.css',
    // '/vendor/font-awesome/css/font-awesome.min.css',
    // '/vendor/dripicons/webfont.css',
    // 'https://fonts.googleapis.com/css?family=Nunito:400,500,700',
    // '/vendor/malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css',
    // '/vendor/keyboard/css/keyboard.css',
    // '/vendor/daterange/css/daterangepicker.min.css',
    // '/vendor/datatable/dataTables.bootstrap4.min.css',
   // 'https://fonts.googleapis.com/css2?family=Iceland&family=Karla:wght@400;700&family=Nova+Square&family=Open+Sans:wght@600&family=Orbitron:wght@900&display=swap',
  //  'https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css',
   // 'https://cdn.materialdesignicons.com/4.8.95/css/materialdesignicons.min.css',
    // '/css/style.default.css',
    // '/plugins/sweetalert2-theme-bootstrap-4/bootstrap-4.min.css',
    // '/plugins/datatables-buttons/css/buttons.bootstrap4.min.css',
    // '/css/style.css'
  ];

  const nonPosCSS = [
    '/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css',
    '/plugins/icheck-bootstrap/icheck-bootstrap.min.css',
    '/plugins/jqvmap/jqvmap.min.css',
    '/dist/css/adminlte.min.css',
    '/plugins/overlayScrollbars/css/OverlayScrollbars.min.css',
    '/plugins/daterangepicker/daterangepicker.css',
    '/plugins/summernote/summernote-bs4.css',
    'https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700'
  ];

  commonCSS.forEach(loadCSS);

  if (pathname === "/pos") {
    posCSS.forEach(loadCSS);
  } else {
    nonPosCSS.forEach(loadCSS);
  }
};

const selectScripts = (pathname) => {
  const commonScripts = [
    '/plugins/jquery/jquery.min.js'
  ];

  const posScripts = [
    // '/plugins/datatables/jquery.dataTables.min.js',
    // '/plugins/datatables-responsive/js/dataTables.responsive.min.js',
    // '/vendor/jquery/jquery-ui.min.js',
    // '/vendor/jquery/bootstrap-datepicker.min.js',
    // '/vendor/jquery/jquery.timepicker.min.js',
    // '/vendor/popper.js/umd/popper.min.js',
    // '/vendor/bootstrap/js/bootstrap.min.js',
    // '/vendor/bootstrap-toggle/js/bootstrap-toggle.min.js',
    // '/vendor/bootstrap/js/bootstrap-select.min.js',
    // '/vendor/keyboard/js/jquery.keyboard.js',
    // '/vendor/keyboard/js/jquery.keyboard.extension-autocomplete.js',
    // '/vendor/jquery.cookie/jquery.cookie.js',
    // '/vendor/jquery-validation/jquery.validate.min.js',
    // '/vendor/malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.concat.min.js',
    // '/vendor/daterange/js/moment.min.js',
    // '/vendor/daterange/js/knockout-3.4.2.js',
    // '/vendor/daterange/js/daterangepicker.min.js',
    // '/vendor/tinymce/js/tinymce/tinymce.min.js',
    // '/vendor/datatable/vfs_fonts.js',
    // '/vendor/datatable/jquery.dataTables.min.js',
    // '/vendor/datatable/dataTables.bootstrap4.min.js',
    // '/vendor/datatable/dataTables.buttons.min.js',
    // '/vendor/datatable/buttons.bootstrap4.min.js',
    // '/vendor/datatable/buttons.colVis.min.js',
    // '/vendor/datatable/buttons.html5.min.js',
    // '/vendor/datatable/buttons.print.min.js',
    // '/vendor/datatable/sum().js',
    // '/vendor/datatable/dataTables.checkboxes.min.js',
    // 'https://cdn.datatables.net/fixedheader/3.1.6/js/dataTables.fixedHeader.min.js',
    // 'https://cdn.datatables.net/responsive/2.2.3/js/dataTables.responsive.min.js',
    // 'https://cdn.datatables.net/responsive/2.2.3/js/responsive.bootstrap.min.js',
    // '/plugins/datatables-buttons/js/dataTables.buttons.min.js',
    // '/plugins/datatables-buttons/js/buttons.bootstrap4.min.js',
    // '/plugins/jszip/jszip.min.js',
    // '/plugins/pdfmake/pdfmake.min.js',
    // '/plugins/pdfmake/vfs_fonts.js',
    // '/plugins/datatables-buttons/js/buttons.html5.min.js',
    // '/plugins/datatables-buttons/js/buttons.print.min.js',
    // '/plugins/datatables-buttons/js/buttons.colVis.min.js'
  ];

  const nonPosScripts = [
    '/plugins/jquery-ui/jquery-ui.min.js',
    '/plugins/bootstrap/js/bootstrap.bundle.min.js',
    '/plugins/chart.js/Chart.min.js',
    '/plugins/sparklines/sparkline.js',
    '/plugins/jqvmap/jquery.vmap.min.js',
    '/plugins/jqvmap/maps/jquery.vmap.usa.js',
    '/plugins/jquery-knob/jquery.knob.min.js',
    '/plugins/moment/moment.min.js',
    '/plugins/daterangepicker/daterangepicker.js',
    '/plugins/tempusdominus-bootstrap-4/js/tempusdominus-bootstrap-4.min.js',
    '/plugins/summernote/summernote-bs4.min.js',
    '/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js',
    '/dist/js/adminlte.js',
    '/dist/js/pages/dashboard.js',
    '/dist/js/demo.js',
    '/plugins/datatables/jquery.dataTables.min.js',
    '/plugins/datatables-bs4/js/dataTables.bootstrap4.min.js',
    '/plugins/datatables-responsive/js/dataTables.responsive.min.js',
    '/plugins/datatables-responsive/js/responsive.bootstrap4.min.js'
  ];

  commonScripts.forEach(script => loadScript(script));

  if (pathname === "/pos") {
    posScripts.forEach(script => loadScript(script));
  } else {
    nonPosScripts.forEach(script => loadScript(script));
  }
};

function App(props) {
  const [menuRoutes, setMenuRoutes] = useState([]);
  const [navCollapse, setNavCollapse] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState('');

  const fetchUser = async () => {
    if (cookie.get('access_token')) {
      const response = await api.get('user');
      auth.saveUserDetails(response.data);

      const routePaths = await routes.routes();
      setMenuRoutes(routePaths);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const collapseSideBar = () => {
    setNavCollapse(!navCollapse);
  };

  const componentName = (e) => {
    setSelectedComponent(e);
  };

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === '/pos') {
      document.body.className = 'layout-top-nav hold-transition';
      document.body.style.height = 'auto';
    } else {
      document.body.className = 'hold-transition sidebar-mini layout-fixed';
      document.body.style.height = null;
    }
    selectCSS(location.pathname);
    selectScripts(location.pathname);
  }, [location]);

  return (
    <div className="wrapper">
      {cookie.get('access_token') ? (
        <>
          {location.pathname === '/pos' ? <PosHeader /> : <Header />}
          {location.pathname !== '/pos' && (
         
              <div className="content-wrapper">
                <Menu
                  routePaths={menuRoutes}
                  componentName={componentName}
                  sideBarCollapsed={navCollapse}
                />
                <ComponentContainer
                  routePaths={menuRoutes}
                  sideBarCollapsed={navCollapse}
                />
              </div>
           
           )} 
        </>
      ) : (
        <Login />
      )}
     </div>
  );
}

export default function AppWithRouter(props) {
  return (
    <Router>
      <App {...props} />
    </Router>
  );
}
