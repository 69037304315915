import fun from './fun';
import { api } from '../services';
import { jsPDF } from 'jspdf';
// import axios from 'axios';
// import CookieService from '../services/cookie';

const SalesOrderPrint = {
  async load(saleId, userName, isDuplicate) {
    try {
      const response = await api.get(`print-receipt/${saleId}`);

      if (response.status === 200 && response.data.status === 200) {
        this.print_data = response.data.sales_order;
        this.printPDFInvoice();
      } else {
        console.error('Failed to fetch sale data');
      }
    } catch (error) {
      console.error('Error fetching sale data:', error);
    }
  },

  printPDFInvoice() {
    let page_number = 1;
    let doc = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      // format: 'a5',
      format: [80, 210],
    });

    let x = 2;
    let y = 10;
    const lineHeight = 6;
    const endX = 78;

    // Title
    doc.setFont('fantasy');
    doc.setFontSize(12);
    doc.text('GAMAGE JEWELLERS', 40, y,'center');

    // Address and Contact
    doc.setFont('helvetica');
    doc.setFontSize(10);
    y += 5;
    doc.text('Address: No 15 Kotugodalla street, Kandy',2, y,'left');
    y += 5;
    doc.text('Phone Number:  0777272954',2, y,'left');

    // Date, Reference, and Customer
    y += 5;
    doc.setFontSize(10);
    doc.text(`Date: ${this.print_data.t_date}`,78, y,'right');
    doc.text(`Cashier: ${this.print_data.user.name}`, 2, y,'left');
    y += 5;
    doc.text(`Reference: ${this.print_data.receipt_no}`,2, y,'left');
    y += lineHeight;
    doc.text(`Customer: ${this.print_data.customer.full_name}`,2, y,'left');
    // y += lineHeight;
    // doc.text(`Customer Contact: ${this.print_data.customer.mobile_1}`,2, y,'left');
 
  

    // Items Table
    y += lineHeight;
    doc.setFontSize(10);
    doc.text('Item', x, y);
   // doc.text('Qty', x+40, y);
    doc.text('Amount', endX, y, 'right');

    doc.setLineWidth(0.5);
    doc.line(x, y + 2, endX + 10, y + 2);

    y += lineHeight;
    this.print_data.sale_details.forEach((item) => {
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(9);
      doc.text(`${item.item.item_name} - ${item.qty}`, x, y);  // Ensure item_id is a string
    //  doc.text(`${item.qty}`, x, y);  // Ensure item_id is a string
      doc.text(`${item.total.toFixed(2)}`, endX, y, 'right');  // Ensure total is a string
      y += lineHeight;
    });

    // Totals
    doc.setLineWidth(0.5);
    doc.line(x, y - 2, endX + 10, y - 2);

    y += 5;
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(10);
    doc.text('Total:', x, y);
    doc.text(`${this.print_data.sub_total.toFixed(2)}`, endX, y, 'right');  // Ensure sub_total is a string

    if (this.print_data.discount) {
      y += lineHeight;
      doc.text('Discount:', x, y);
      doc.text(`${this.print_data.discount.toFixed(2)}`, endX, y, 'right');  // Ensure discount is a string
    }
    
    y += 5;
    doc.text('Paid:', x, y);
    doc.text(`${this.print_data.paid_amount.toFixed(2)}`, endX, y, 'right');

    y += 5;
    doc.text('Grand Total:', x, y);
    doc.text(`${this.print_data.grand_total.toFixed(2)}`, endX, y, 'right');  // Ensure total is a string

    // Payment Info
    y += 5;
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(10); 
    doc.text('Balance:', x, y);
    doc.text(`${this.print_data.balance.toFixed(2)}`, endX, y, 'right');  // Ensure total is a string


    // Footer
    y += lineHeight * 2;
    doc.setFontSize(11); 
    doc.setFont('helvetica', 'italic');
    doc.text('Thank you. Please come again!', x+40, y, 'center');


    // Print or Save
   // doc.autoPrint();
    let newWindow = window.open(doc.output('bloburl'), '_blank');
    // newWindow.onafterprint = () => {
    //   newWindow.close();
    // };
    // setTimeout(function () {
    //   newWindow.close();
    // }, 120000);
  },
};


export default SalesOrderPrint;
