import React, { useState, useEffect } from 'react';
import { api, msg, cookie } from '../../../services';
import { DataTable, FormModal, ListView, SystemButton } from '../../../components';
import moment from 'moment';

const Brand = () => {
  // Module name
  const moduleName = 'Brand';

  /* --- State declarationss --- */

  const [entities, setEntities] = useState([]);

  const [newData, setNewData] = useState({
    code: '',
    name: '',
  });

  // Form modal state
  const [showModalState, setShowModalState] = useState(false);

  // Data edit state
  const [isEdit, setIsEdit] = useState(false);

  // Data loading status
  const [isLoading, setIsLoading] = useState(false);

  // Selected data
  const [selectedId, setSelectedId] = useState('');

  // List view states
  const dataColumns = [
    {
      header: "Brand Code",
      field: "code",
      showFilterMenu: false,
      searchable: true
    },
    { header: "Name", field: "name", showFilterMenu: false,searchable: true },
  ];
  let dataRows = [];

  /* --- End of state declarations --- */

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // if (showModalState === false) {
    //   resetForm();
    // }
  }, [showModalState]);

  /* --- Component functions --- */

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const response = await api.get('brand');

      console.log(response.data.entities);

      if (response.error) {
        Object.values(response.error).forEach((err) => {
          msg.error(err[0]);
        });
        return;
      }

      response.data.entities.map((entity) => {
        dataRows.push({
          id: entity.id,
          code: entity.code,
          name: entity.name,
        });
      });

      setEntities(dataRows);
    } catch (error) {
      console.log(error);
      msg.error('Unable to fetch data!');
    } finally {
      setIsLoading(false);
    }
  };



  const handleValueChange = (e) => {
    const targetInput = e.target;
    const inputName = targetInput.name;
    // const inputValue = targetInput.value;
    const inputValue =
      targetInput.type === 'checkbox' ? targetInput.checked : targetInput.value;

    setNewData({
      ...newData,
      [inputName]: inputValue,
    });
  };

  const editRow = (dataObj) => {
    console.log(dataObj);
    setShowModalState(true);
    setNewData({
      code: dataObj.code,
      name: dataObj.name,
    });

    setIsEdit(true);
    setSelectedId(dataObj.id);
  };

  const toggleFormModal = () => {
    setShowModalState(!showModalState);
  };

  const handleSubmit = async () => {
    await save();
  };

  const save = async () => {
    if (isEdit === false) {
      try {
        const response = await api.post('brand').values(newData);
        if (response.status == 200 && response.data.status == 200) {
          msg.success(response.data.message);
          resetForm();
          fetchData();
          setShowModalState(false);
        } else if (response.status == 200 && response.data.status == 500) {
          msg.error(response.data.message);
        } else if (response.status == 200 && response.data.status == 400) {
          Object.values(response.data.message).forEach((err) => {
            msg.error(err[0]);
          });
        } else if (response.data.status == 400) {
          Object.values(response.message).forEach((err) => {
            msg.error(err[0]);
          });
        } else if (response.data.status == 520) {
          msg.warning(response.data.message);
        }
      } catch (error) {
        msg.error(error);
        return console.log(error);
      }
    } else {
      try {
        const response = await api
          .update(`add-brand/${selectedId}/update`)
          .values(newData);
          if (response.status == 200 && response.data.status == 200) {
            msg.success(response.data.message);
            resetForm();
            fetchData();
            setIsEdit(false);
            setSelectedId('');
            setShowModalState(false);
          } else if (response.status == 200 && response.data.status == 500) {
            msg.error(response.data.message);
          } else if (response.status == 200 && response.data.status == 400) {
            Object.values(response.data.message).forEach((err) => {
              msg.error(err[0]);
            });
          } else if (response.data.status == 400) {
            Object.values(response.message).forEach((err) => {
              msg.error(err[0]);
            });
          } else if (response.data.status == 520) {
            msg.warning(response.data.message);
          }
      } catch (error) {
        msg.error(error);
        return console.error(error);
      } 
    }
  };
  const deleteRow = async (dataObj) => {
    try {
      const response = await api.delete(`brand/${dataObj.id}`);
      if (response.status == 200 && response.data.status == 200) {
        msg.success(response.data.message);
        fetchData();
      } else if (response.status == 200 && response.data.status == 500) {
        msg.error(response.data.message);
      } else if (response.status == 200 && response.data.status == 400) {
        Object.values(response.data.message).forEach((err) => {
          msg.error(err[0]);
        });
      } else if (response.data.status == 400) {
        Object.values(response.message).forEach((err) => {
          msg.error(err[0]);
        });
      } else if (response.data.status == 520) {
        msg.warning(response.data.message);
      }
    } catch (error) {
      msg.error(error);
    } 
  };

  const resetForm = () => {
    setNewData({
      ...newData,
      code: '',
      name: '',
    });

    setIsEdit(false);
  };



  /* --- End of component functions --- */

  /* --- Component renders --- */

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{moduleName}</h1>
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header border-0">
                <div className="col-md-12 d-block">
                  <SystemButton
                    type="add-new"
                    classes={'btn-sm btn  btn-success float-sm-right'}
                    method={toggleFormModal}
                    showText
                    btnText="Add Brand"
                  />
                </div>
              </div>
              <div className="card-body">
              <DataTable
              columns={dataColumns}
      dataList={entities}
      edit={editRow}
      deletedata={deleteRow}
      />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* End of form modal componenet */}
      <FormModal
        moduleName={moduleName}
        modalState={showModalState}
        toggleFormModal={toggleFormModal}
        width="50%"
      >
        {/* <form onSubmit={handleSubmit} className="compactForm">
        </form> */}
        <div>
          <div className="modal-body">
            <div className="row">
              <label htmlFor="code" className="col-sm-2 col-form-label">
                Brand Code
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  name="code"
                  id="code"
                  maxLength="20"
                  minLength="10"
                  className="form-control form-control-sm"
                  value={newData.code}
                  onChange={handleValueChange}
                  
                />
              </div>
            </div>

            <div className="row">
              <label htmlFor="name" className="col-sm-2 col-form-label">
                Name
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  name="name"
                  id="name"
                  maxLength="20"
                  minLength="10"
                  className="form-control form-control-sm"
                  value={newData.name}
                  onChange={handleValueChange}
                />
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <SystemButton
              type={'close'}
              method={toggleFormModal}
              showText={true}
            />
            <SystemButton
              type="no-form-save"
              method={handleSubmit}
              showText={true}
            />
          </div>
        </div>
      </FormModal>
     

      {/* List view componenet */}
      {/* <ListView
        columns={dataColumns}
        rows={entities}
        edit={editRow}
        loadingState={isLoading}
        searchAndFetch={searchAndFetch}
        actionsColumn
        showEditButton
        resetSearch={resetSearch}
        rowKey="code"
      /> */}
      {/* End of list view component */}
    </>
  );

  /* --- End of component renders --- */
};

export default Brand;
